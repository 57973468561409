import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "@syncfusion/ej2-base";
import React, { Reducer, useReducer } from "react";
import {
  getLookUpItems,
  getSearchedLookUpItems,
  getUniqueFilteredLookUpDropdownItems,
} from "../../../../../RAFComponents/helpers/AutoCompleteMUIHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { Constants } from "../../../../../constants/Common/Constants";

const defaultTake = 50;
const defaultSkip = 0;

interface IState {
  isLoading: boolean;
  dropdownState: boolean;
  dataSource: LookUpRow[];
  searchedText: string;
  prevSearchedText: string;
  skipItemsOnScroll: number;
}

const RAFAutoCompleteMUIComponent = ({
  question,
  url,
  moduleName,
  customFilter,
}) => {
  const [questionValue, setQuestionValue] = React.useState(question.value);
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  const apiUrl = Constants.baseAPIUrl + url;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: false,
      dropdownState: false,
      dataSource: [],
      searchedText: null,
      prevSearchedText: null,
      skipItemsOnScroll: 0,
    }
  );

  const onOpenAutoDropdown = () => {
    setState({ dropdownState: true, skipItemsOnScroll: 0 });
    getLookUpDroopDownItems();
  };

  const getLookUpDroopDownItems = async () => {
    if (isNotEmptyArray(state.dataSource)) {
    } else {
      if (isNotNullAndUndefined(state.searchedText)) {
        return;
      }
      setState({ isLoading: true });

      let customFilterObj = null;

      if (IsNotNullOrWhiteSpace(customFilter)) {
        try {
          customFilterObj = JSON.parse(customFilter);
        } catch (error) {
          console.error("Error parsing JSON string:", error);
        }
      }

      const lookUpDataItems = await getLookUpItems(
        apiUrl,
        defaultSkip,
        defaultTake,
        null,
        moduleName,
        customFilterObj,
        null
      );

      if (isNotEmptyArray(lookUpDataItems)) {
        const data: LookUpRow[] = lookUpDataItems;

        const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

        setState({ dataSource: uniqueData, isLoading: false });
      } else {
        setState({ dropdownState: false, dataSource: [], isLoading: false });
      }
    }
  };

  const handleInputChange = (event, value) => {
    //calls when text input changes
    if (
      isNotNullAndUndefined(event) &&
      event.isTrusted === true &&
      event.type === "change"
    ) {
      setHandleInputDebounce(value);
    }
  };

  const setHandleInputDebounce = debounce(async (newSearchedText) => {
    setState({ isLoading: true, skipItemsOnScroll: defaultSkip });
    const searchedText = newSearchedText;
    let customFilterObj = null;

    if (IsNotNullOrWhiteSpace(customFilter)) {
      try {
        customFilterObj = JSON.parse(customFilter);
      } catch (error) {
        console.error("Error parsing JSON string:", error);
      }
    }
    const responseLookUpItems = await getSearchedLookUpItems(
      apiUrl,
      defaultSkip,
      defaultTake,
      searchedText,
      moduleName,
      customFilterObj,
      state.dataSource,
      state.prevSearchedText
    );

    const lookUpItems = isNotNullAndUndefined(responseLookUpItems)
      ? responseLookUpItems.lookUpDataItems
      : [];

    if (isNotEmptyArray(lookUpItems)) {
      const data: LookUpRow[] = [...lookUpItems];

      const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

      setState({
        dataSource: uniqueData,
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else {
      setState({
        dropdownState: false,
        dataSource: [],
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    }
  }, 500);

  const onChangeSearchText = (selectedValue: LookUpRow | string, input) => {
    if (typeof selectedValue === "string") {
      //on enter key press
      const existingItem =
        IsNotNullOrWhiteSpace(selectedValue) &&
        isNotEmptyArray(state.dataSource)
          ? state.dataSource.find(
              (x) =>
                isNotNullAndUndefined(x.Value) &&
                x.Value.toLowerCase() === selectedValue.toLowerCase()
            )
          : null;

      if (isNotNullAndUndefined(existingItem)) {
        onChangeInput(existingItem, input);
      } else {
        onChangeInput(null, input, selectedValue);
      }
    } else {
      onChangeInput(selectedValue, input);
    }
  };

  const onChangeInput = (newValue: LookUpRow, input, inputText?: string) => {
    if (IsNotNullOrWhiteSpace(inputText)) {
      const prevValue = input.value;
      if (prevValue !== inputText) {
        setQuestionValue(inputText !== null ? inputText : null);
        input.value = inputText !== null ? inputText : null;
      }
    } else {
      const prevValue = input.value;
      const itemUID = isNotNullAndUndefined(newValue) ? newValue.UID : null;
      const itemValue = isNotNullAndUndefined(newValue) ? newValue.Value : null;
      if (prevValue !== itemValue) {
        setQuestionValue(itemValue !== null ? itemValue : null);
        input.value = itemValue !== null ? itemValue : null;
      }
    }
  };

  const loadMoreOptions = async () => {
    const { skipItemsOnScroll, searchedText, dataSource } = state;
    const newSkipItemsOnScroll = skipItemsOnScroll + defaultTake;
    if (
      isNotEmptyArray(dataSource) &&
      newSkipItemsOnScroll > dataSource.length
    ) {
      return;
    }
    let customFilterObj = null;

    if (IsNotNullOrWhiteSpace(customFilter)) {
      try {
        customFilterObj = JSON.parse(customFilter);
      } catch (error) {
        console.error("Error parsing JSON string:", error);
      }
    }
    const responseLookUpItems = await getSearchedLookUpItems(
      apiUrl,
      newSkipItemsOnScroll,
      defaultTake,
      searchedText,
      moduleName,
      customFilterObj,
      state.dataSource,
      state.prevSearchedText
    );

    const lookUpItems = isNotNullAndUndefined(responseLookUpItems)
      ? responseLookUpItems.lookUpDataItems
      : [];

    if (isNotEmptyArray(lookUpItems)) {
      const responseData: LookUpRow[] = isNotEmptyArray(lookUpItems)
        ? [...lookUpItems]
        : [];

      const data = [...dataSource, ...responseData];

      const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dataSource: uniqueData,
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else {
      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dropdownState: false,
        dataSource: [],
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    }
  };

  const onCloseAutoDropdown = () => {
    setState({
      skipItemsOnScroll: 0,
      dropdownState: false,
      isLoading: false,
      searchedText: null,
      prevSearchedText: null,
      dataSource: IsNotNullOrWhiteSpace(state.searchedText)
        ? []
        : state.dataSource,
    });
  };

  return (
    <div className="col-12">
      <Autocomplete
        id={question.name}
        value={question.value}
        freeSolo
        open={state.dropdownState}
        onOpen={() => {
          onOpenAutoDropdown();
        }}
        onClose={() => {
          onCloseAutoDropdown();
        }}
        options={state.dataSource}
        isOptionEqualToValue={(option, value) => option.Value === value}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.Value
        }
        getOptionKey={(option) =>
          typeof option === "string" ? option : option.UID
        }
        loading={state.isLoading}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          onChangeSearchText(newValue, question);
        }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            // label={props.placeholder}
            margin="none"
            variant="outlined"
            //placeholder={props.placeholder}

            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {state.isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disabled={disabled}
          />
        )}
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight ===
              listboxNode.scrollHeight
            ) {
              // The scroll has hit the bottom, load more options
              loadMoreOptions();
            }
          },
        }}
        onBlur={(event) => {
          //console.log('onBlur', event);
          const searchText = event.target["value"];
          onChangeSearchText(searchText, question);
        }}
      />
    </div>
  );
};

export default RAFAutoCompleteMUIComponent;
