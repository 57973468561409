import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { isArrayOfType } from "../../../../../RAFComponents/helpers/AppHelper";

const RAFMultiSelectDropdownMUIComponent = ({ question }) => {
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  const [questionValue, setQuestionValue] = React.useState(question.value);
  let choiceOptions = question.choices;
  let isStringArray = isArrayOfType(choiceOptions, "string");
  let newChoiceOptions = [];
  if (isStringArray) {
    choiceOptions &&
      choiceOptions.forEach((choice) => {
        newChoiceOptions.push({
          value: choice,
          text: choice,
        });
      });
  } else {
    choiceOptions &&
      choiceOptions.forEach((choice) => {
        newChoiceOptions.push({
          value: choice.value,
          text: choice.text ?? choice.value,
          colorCode: choice.colorCode,
        });
      });
  }

  const handleChange = (event: SelectChangeEvent<string[]>, input) => {
    const targetValue = event.target.value as string[];

    const newValue = isNotEmptyArray(targetValue) ? targetValue : [];

    const myArrayFiltered = newChoiceOptions.filter((el) => {
      if (isNotEmptyArray(newValue)) {
        return newValue.some((f) => {
          return f === el.text || f === el.value;
        });
      }
    });

    const selectedItems = isNotEmptyArray(myArrayFiltered)
      ? myArrayFiltered.map((e) => {
          return e.value;
        })
      : [];

    question.value = selectedItems;
    setQuestionValue(selectedItems);
  };

  const myArrayFiltered = isNotEmptyArray(newChoiceOptions)
    ? newChoiceOptions.filter((el) => {
        return (
          isNotEmptyArray(questionValue) &&
          questionValue.some((f) => {
            return f === el.text || f === el.value;
          })
        );
      })
    : [];

  const selectedItems = isNotEmptyArray(myArrayFiltered)
    ? myArrayFiltered.map((e) => {
        return e.text;
      })
    : [];

  return (
    <div className="row g-3 gy-2">
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedItems}
        onChange={(e) => handleChange(e, question)}
        input={<OutlinedInput />}
        renderValue={(selected) => selected.join(", ")}
        hiddenLabel={true}
        className="raf_mui_input"
        {...(isNotNullAndUndefined(question.placeholder)
          ? { placeholder: question.placeholder }
          : {})}
        disabled={disabled}
      >
        {newChoiceOptions &&
          newChoiceOptions.map((name) => (
            <MenuItem key={name.text} value={name.text}>
              <ListItemText primary={name.text} />
              <Checkbox
                checked={
                  isNotNullAndUndefined(questionValue) &&
                  questionValue.indexOf(name.value) > -1
                }
              />
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default RAFMultiSelectDropdownMUIComponent;
