import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { Question } from "survey-core";
import CustomCardWidget from "../../../../../RAFComponents/Navigation/CustomCardWidget";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import ChecklistItemTransDocument from "../../../../../RAFModules/CareESIO/ChecklistItemTrans/ChecklistItemTransDocument";
import { ChecklistItemTransRow } from "../../../../../RAFModules/CareESIO/ChecklistItemTrans/ChecklistItemTransRow";
import { getChecklistTemplateItemListByTemplateUID } from "../../../../../RAFModules/CareESIO/ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemHelper";
import { ChecklistTemplateItemRow } from "../../../../../RAFModules/CareESIO/ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemRow";
import { contextObjecType, contextObjectUID } from "./Initializations";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { CareEsioEntity } from "../../../../../constants/CareESIO/CareEsioConstants";

interface RAFEmployeeDocumentComponentProps {
  question?: Question;
  requirementTemplate?: LookUpRow;
  selectedItemTemplate?: LookUpRow;
  selectedModuleName?: string;
}

interface IState {
  selectedChecklistTemplateItem?: ChecklistTemplateItemRow;
  checklistTemplateItems?: ChecklistTemplateItemRow[];
  selectedTemplateItem?: LookUpRow;
  rafObject?: any;
  checklistItemTrans?: ChecklistItemTransRow;
}

const RAFComplainceDocumentComponent: React.FC<
  RAFEmployeeDocumentComponentProps
> = ({
  question,
  requirementTemplate,
  selectedItemTemplate,
  selectedModuleName,
}) => {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedTemplateItem: isNotNullAndUndefined(selectedItemTemplate)
        ? selectedItemTemplate
        : null,
      checklistTemplateItems: [],
      selectedChecklistTemplateItem: null,
      checklistItemTrans: null,
    }
  );

  let displayMode = false;
  if ((question.survey as any).mode === "display") {
    displayMode = true;
  }

  let mode = (question.survey as any).mode;

  const isDesignMode = question.survey.isDesignMode;
  const surveyValue = question.survey as any;

  const rafObjectUID = surveyValue.getValue(contextObjectUID);
  const moduleName = surveyValue.getValue(contextObjecType);
  if (
    isNotNullAndUndefined(requirementTemplate) &&
    isNotNullAndUndefined(requirementTemplate.UID)
  ) {
    var objectUID = requirementTemplate.UID;
  }
  useEffect(() => {
    // Logic to handle changes in pageUrl

    refresh();
  }, [requirementTemplate]);

  const refresh = async () => {
    if (isNotNullAndUndefined(objectUID)) {
      let checklistTemplateItems =
        await getChecklistTemplateItemListByTemplateUID(objectUID);
      if (isNotEmptyArray(checklistTemplateItems)) {
        if (
          isNotNullAndUndefined(state.selectedTemplateItem) &&
          isNotNullAndUndefined(state.selectedTemplateItem.UID)
        ) {
          let selectedItem = checklistTemplateItems.find(
            (item) => item.UID === state.selectedTemplateItem.UID
          );
          setState({
            selectedChecklistTemplateItem: selectedItem,
            checklistTemplateItems,
          });
        } else {
          setState({ checklistTemplateItems });
        }
      }
    }
  };
  const handleChange = (value) => {
    let { checklistTemplateItems } = state;
    if (selectedModuleName === RAFEntityName.Employee) {
      question.selectedItemTemplate = {
        UID: value[fields.value],
        Value: value[fields.text],
      };
      setState({ selectedTemplateItem: question.selectedItemTemplate });
    } else if (selectedModuleName === CareEsioEntity.CareRecipient.EntityName) {
      question.clientSelectedItemTemplate = {
        UID: value[fields.value],
        Value: value[fields.text],
      };
      setState({ selectedTemplateItem: question.clientSelectedItemTemplate });
    }

    if (isNotEmptyArray(checklistTemplateItems)) {
      let selectedChecklistTemplateItem = checklistTemplateItems.find(
        (item) => item.UID === value[fields.value]
      );

      setState({ selectedChecklistTemplateItem });
    }
  };

  let fields = { text: "label", value: "value" };
  if (isDesignMode) {
    let { selectedChecklistTemplateItem, checklistTemplateItems } = state;
    if (isNotEmptyArray(checklistTemplateItems)) {
      let dataSource = checklistTemplateItems.map((item) => {
        return {
          label: item.Title,
          value: item.UID,
        };
      });
      return (
        <DropDownListComponent
          change={(e) => {
            if (e.isInteracted) {
              handleChange(e.itemData !== null ? e.itemData : null);
            }
          }}
          value={
            selectedChecklistTemplateItem &&
            selectedChecklistTemplateItem.UID !== null
              ? selectedChecklistTemplateItem.UID
              : null
          }
          dataSource={dataSource}
          fields={fields}
          placeholder="Select a document"
        />
      );
    } else {
      return <div>Please choose requirement template from settings</div>;
    }
  } else {
    if (isNullOrUndefined(rafObjectUID) && mode === "edit") {
      if (isNotNullAndUndefined(state.selectedChecklistTemplateItem)) {
        return (
          <>
            <CustomCardWidget>
              <div>
                <div className="row gx-2">
                  <div className="col">
                    <div className="row gy-1 gx-0">
                      <div className="col-12">
                        <div className="subtitle_2">
                          {state.selectedChecklistTemplateItem.Title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomCardWidget>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (
      isNotNullAndUndefined(rafObjectUID) &&
      (mode === "display" || mode === "edit")
    ) {
      let { selectedTemplateItem } = state;
      return (
        <ChecklistItemTransDocument
          relatedToType={moduleName}
          relatedUID={rafObjectUID}
          checklistItems={[
            {
              UID: selectedTemplateItem.UID,
              Value: selectedTemplateItem.Value,
            },
          ]}
          displayMode={displayMode}
        />
      );
    }
  }
};

export default React.memo(RAFComplainceDocumentComponent);
