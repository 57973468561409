import moment from "moment";
import React from "react";
import {
  convertUTCDateToLocalTimezone,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { FormLibraryScheduleRow } from "../../FormLibrarySchedule/FormLibraryScheduleRow";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { CareEsioEntity } from "../../../../../constants/CareESIO/CareEsioConstants";

const RAFFormScheduleComponentRenderer = ({ question }) => {
  let formLibrarySchedule: FormLibraryScheduleRow = (
    question.survey as any
  ).getValue(question.name);
  let lastRunLocalDate = isNotNullAndUndefined(formLibrarySchedule)
    ? convertUTCDateToLocalTimezone(formLibrarySchedule.LastCompletedDate)
    : null;

  let lastRunDate = isNotNullAndUndefined(lastRunLocalDate)
    ? moment(lastRunLocalDate).format("DD/MM/YYYY")
    : null;

  let nextRunLocalDate = isNotNullAndUndefined(formLibrarySchedule)
    ? convertUTCDateToLocalTimezone(formLibrarySchedule.NextRunDate)
    : null;

  let nextRunDate = isNotNullAndUndefined(nextRunLocalDate)
    ? moment(nextRunLocalDate).format("DD/MM/YYYY")
    : null;

  let relatedToLabel = "";
  if (
    isNotNullAndUndefined(formLibrarySchedule) &&
    isNotNullAndUndefined(formLibrarySchedule.RelatedToType)
  ) {
    if (formLibrarySchedule.RelatedToType === RAFEntityName.Contact) {
      relatedToLabel = "Contact Name";
    } else if (formLibrarySchedule.RelatedToType === RAFEntityName.Ticket) {
      relatedToLabel = "Ticket#";
    } else if (formLibrarySchedule.RelatedToType === RAFEntityName.Employee) {
      relatedToLabel = "Employee Name";
    } else if (
      formLibrarySchedule.RelatedToType ===
      CareEsioEntity.CareRecipient.EntityName
    ) {
      relatedToLabel = "Client Name";
    }
  }

  if (isNotNullAndUndefined(formLibrarySchedule)) {
    return (
      <div>
        <div className="sd-row sd-clearfix">
          <div
            style={{
              flex: "1 1 100%",
              minWidth: "min(100%, 300px)",
              maxWidth: "100%",
            }}
          >
            {isNotNullAndUndefined(formLibrarySchedule.RelatedTo) && (
              <div className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small">
                <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top py-1">
                  <h5
                    className="sd-title sd-element__title sd-question__title"
                    id="sq_106_ariaTitle"
                  >
                    <span className="sv-string-viewer sv-string-viewer--multiline">
                      {relatedToLabel}
                    </span>
                  </h5>
                </div>

                <div className="sd-text__content sd-question__content mb-3">
                  <span>{formLibrarySchedule.RelatedTo}</span>
                </div>
              </div>
            )}
            <div className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small">
              <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top py-1">
                <h5
                  className="sd-title sd-element__title sd-question__title"
                  id="sq_106_ariaTitle"
                >
                  <span className="sv-string-viewer sv-string-viewer--multiline">
                    Person Responsible
                  </span>
                </h5>
              </div>

              <div className="sd-text__content sd-question__content mb-3">
                <span>
                  {isNotNullAndUndefined(formLibrarySchedule.PersonResponsible)
                    ? formLibrarySchedule.PersonResponsible
                    : "Not set"}
                </span>
              </div>
            </div>
            <div className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small">
              <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top py-1">
                <h5
                  className="sd-title sd-element__title sd-question__title"
                  id="sq_106_ariaTitle"
                >
                  <span className="sv-string-viewer sv-string-viewer--multiline">
                    Frequency
                  </span>
                </h5>
              </div>

              <div className="sd-text__content sd-question__content mb-3">
                <span>
                  {isNotNullAndUndefined(formLibrarySchedule.Frequency)
                    ? formLibrarySchedule.Frequency
                    : "Not set"}
                </span>
              </div>
            </div>
            <div className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small">
              <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top py-1">
                <h5
                  className="sd-title sd-element__title sd-question__title"
                  id="sq_106_ariaTitle"
                >
                  <span className="sv-string-viewer sv-string-viewer--multiline">
                    Last Run
                  </span>
                </h5>
              </div>

              <div className="sd-text__content sd-question__content mb-3">
                <span>{lastRunDate ?? "Not set"}</span>
              </div>
            </div>
            <div className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small">
              <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top py-1">
                <h5
                  className="sd-title sd-element__title sd-question__title"
                  id="sq_106_ariaTitle"
                >
                  <span className="sv-string-viewer sv-string-viewer--multiline">
                    Next Schedule
                  </span>
                </h5>
              </div>

              <div className="sd-text__content sd-question__content mb-3">
                <span>{nextRunDate ?? "Not set"}</span>
              </div>
            </div>
            <div className="sd-element--nested sd-element sd-question sd-row__question sd-question--title-top sd-row__question--small">
              <div className="sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top py-1">
                <h5
                  className="sd-title sd-element__title sd-question__title"
                  id="sq_106_ariaTitle"
                >
                  <span className="sv-string-viewer sv-string-viewer--multiline">
                    Description
                  </span>
                </h5>
              </div>

              <div className="sd-text__content sd-question__content mb-3">
                <span>
                  {isNotNullAndUndefined(formLibrarySchedule.Description)
                    ? formLibrarySchedule.Description
                    : "Not set"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default React.memo(RAFFormScheduleComponentRenderer);
