import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import React from "react";
import {
  Guid,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { isArrayOfType } from "../../../../../RAFComponents/helpers/AppHelper";

const RAFRadioButtonComponent = ({ question }) => {
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  const [questionValue, setQuestionValue] = React.useState(question.value);
  const questionDisplayStyle = question["displayStyle"];
  const questionJsonObj = question["jsonObj"];
  let choiceOptions = questionJsonObj["choices"];
  let isStringArray = isArrayOfType(choiceOptions, "string");
  let newChoiceOptions = [];
  if (isStringArray) {
    choiceOptions &&
      choiceOptions.forEach((choice) => {
        newChoiceOptions.push({
          value: choice,
          text: choice,
        });
      });
  } else {
    choiceOptions &&
      choiceOptions.forEach((choice) => {
        newChoiceOptions.push({
          value: choice.value,
          text: choice.text ?? choice.value,
          colorCode: choice.colorCode,
        });
      });
  }

  const handleChange = (value) => {
    question.value = value;
    setQuestionValue(value);
  };

  if (questionDisplayStyle === "Default") {
    return (
      <div className="row g-3 gy-2">
        {newChoiceOptions.map((item, index) => {
          return (
            <div
              className={"col-auto"}
              key={index}
              //moving onclick to parent as it is not working inside dialog

              onClick={() => {
                if (disabled) return;
                handleChange(item.value);
              }}
            >
              <RadioButtonComponent
                // className={
                //   props.radioButtonSM === true
                //     ? "radioButton-sm"
                //     : ""
                // }
                //id={`rbn_${item.value.toString()}`}
                key={index}
                label={item.text}
                value={item.value}
                name={question.name}
                change={(e) => {
                  //moving onclick to parent as it is not working inside dialog
                  //handleChange(e !== null ? e.value : null);
                }}
                checked={questionValue === item.value}
                disabled={disabled}
              />
            </div>
          );
        })}
      </div>
    );
  } else if (questionDisplayStyle === "Custom Toggle Button") {
    return (
      <div className="customToggleButton_outer">
        <div
          className={newChoiceOptions.length > 2 ? `row g-0` : `row g-0 gx-2`}
        >
          {newChoiceOptions.map((item, index) => {
            let indexVal: string = Guid.newGuid();
            return (
              <div
                className={"col"}
                key={index}
                //moving onclick to parent as it is not working inside dialog

                onClick={() => {
                  if (disabled) return;
                  handleChange(item.value);
                }}
              >
                <input
                  style={{ display: "none" }}
                  type="radio"
                  id={"radio_" + indexVal}
                  name={question.name}
                  value={item.value}
                  onChange={(e) => {
                    //radioChecked(item.value, input, index);
                    //moving onclick to parent as it is not working inside dialog
                  }}
                  checked={questionValue === item.value}
                  disabled={disabled}
                />
                <label
                  className={
                    question.value === item.value
                      ? `custom-radio-btn active customToggleButton`
                      : `custom-radio-btn customToggleButton`
                  }
                  htmlFor={"radio_" + indexVal}
                  {...(isNotNullAndUndefined(item.colorCode)
                    ? {
                        style: {
                          backgroundColor: item.colorCode,
                          color: "#fff",
                        },
                      }
                    : {})}
                >
                  {/* {isNotNullAndUndefined(item.IconCss) ? (
                <i className={item.IconCss + " pe-1"}></i>
              ) : null} */}
                  {item.text}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (
    questionDisplayStyle === "Custom Button" ||
    questionDisplayStyle === "Custom Button With Color"
  ) {
    return (
      <div
        className={
          newChoiceOptions.length > 0
            ? `row g-0 gx-2${newChoiceOptions.length > 4 ? " flex-column" : ""}`
            : `row g-0 gx-2`
        }
      >
        {newChoiceOptions.map((item, index) => {
          let indexVal: string = Guid.newGuid();
          return (
            <div
              className={newChoiceOptions.length > 2 ? "col-auto" : "col"}
              key={index}
              //moving onclick to parent as it is not working inside dialog

              onClick={() => {
                if (disabled) return;
                handleChange(item.value);
              }}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                id={"radio_" + indexVal}
                name={question.name}
                value={item.value}
                onChange={(e) => {
                  //radioChecked(item.value, input, index);
                  //moving onclick to parent as it is not working inside dialog
                }}
                checked={questionValue === item.value}
                disabled={disabled}
              />
              <label
                className={
                  question.value === item.value
                    ? `custom-radio-btn active ${
                        questionDisplayStyle === "Custom Button With Color"
                          ? "custom-radio-btn-color"
                          : ""
                      }`
                    : `custom-radio-btn ${
                        questionDisplayStyle === "Custom Button With Color"
                          ? "custom-radio-btn-color"
                          : ""
                      }`
                }
                htmlFor={"radio_" + indexVal}
                {...(isNotNullAndUndefined(item.colorCode) &&
                questionDisplayStyle === "Custom Button With Color"
                  ? {
                      style: {
                        backgroundColor: item.colorCode,
                        color: "#fff",
                      },
                    }
                  : {})}
              >
                {/* {isNotNullAndUndefined(item.IconCss) ? (
                <i className={item.IconCss + " pe-1"}></i>
              ) : null} */}
                {item.text}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
};

export default RAFRadioButtonComponent;
