import React, { useEffect, useState } from "react";
import { RetrieveRecord } from "../../../../../RAFComponents/helpers/AppHelper";
import { isNotNullAndUndefined } from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { Question } from "survey-core";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import RAFHtmlPageContentDetails from "../../../../../RAFModules/ActiveContacts/Document/Library/RAFHtmlPageContentDetails";
//import ManageAction from "../../../../../SkytrustModules/Actions/ManageAction";
interface RAFPageComponentProps {
  question?: Question;
  pageUrl?: LookUpRow;
}
const RAFPageComponentRenderer: React.FC<RAFPageComponentProps> = ({
  question,
  pageUrl,
}) => {
  const [pageContent, setPageContent] = useState(null);
  const [showPageContent, setShowPageContent] = useState(false);

  let displayMode = false;
  if ((question.survey as any).mode === "display") {
    displayMode = true;
  }
  const isDesignMode = question.survey.isDesignMode;

  if (isNotNullAndUndefined(pageUrl) && isNotNullAndUndefined(pageUrl.UID)) {
    var objectUID = pageUrl.UID;
  }
  useEffect(() => {
    // Logic to handle changes in pageUrl
    refresh();
  }, [pageUrl]);

  const refresh = async () => {
    if (isNotNullAndUndefined(objectUID) && isDesignMode === false) {
      let pageContent = await RetrieveRecord(
        objectUID,
        RAFEntityName.ContentLibrary
      );
      setPageContent(pageContent);
    } else {
      setPageContent(null);
    }
  };

  const managePageContent = () => {
    if (showPageContent) {
      return (
        <div className="h-100">
          <div className="e-dlg-content-outer">
            <div className="e-dlg-headerContent">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className={"e-dlg-header"} id="_title">
                  Preview
                </div>
                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  iconCss="fas fa-xmark"
                  onClick={managePageContentDialogClose.bind(this)}
                ></ButtonComponent>
              </div>
            </div>
            <div className="e-dlg-body-content">
              <RAFHtmlPageContentDetails contentLibraryRow={pageContent} />
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const managePageContentDialogClose = () => {
    setShowPageContent(false);
  };

  const managePageContentDialogOpen = () => {
    setShowPageContent(true);
  };

  const previewBtnClicked = async () => {
    let pageContent = await RetrieveRecord(
      objectUID,
      RAFEntityName.ContentLibrary
    );
    setPageContent(pageContent);
    setShowPageContent(true);
  };
  if (isDesignMode) {
    if (isNotNullAndUndefined(objectUID)) {
      return (
        <div>
          <ButtonComponent
            type="button"
            cssClass="primary-custom-button"
            iconCss="fas fa-eye"
            content="Preview"
            onClick={previewBtnClicked.bind(this)}
          ></ButtonComponent>
          {showPageContent && (
            <DialogComponent
              id={"manageAction_dlg_outerDiv"}
              visible={showPageContent}
              cssClass="centerDialog-lg dlg-new-style fixed-height dlg-new-style"
              content={managePageContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={managePageContentDialogClose.bind(this)}
            ></DialogComponent>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    if (isNotNullAndUndefined(pageContent)) {
      return (
        <div>
          <RAFHtmlPageContentDetails contentLibraryRow={pageContent} />
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default React.memo(RAFPageComponentRenderer);
