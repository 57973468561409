import React, { useEffect } from "react";

import moment from "moment";
import { Question } from "survey-core";
import {
  convertUTCDateToLocalTimezone,
  hexToRGBA,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
} from "../../../../../RAFComponents/helpers/utils";
import { RAFStatusNameWithColor } from "../../../../../constants/Common/Constants";

interface RAFCustomProcessStepRendererProps {
  question?: Question;
}

const RAFCustomProcessStepRenderer: React.FC<
  RAFCustomProcessStepRendererProps
> = ({ question }) => {
  let displayMode = false;
  if ((question.survey as any).mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (displayMode === true) {
    disabled = true;
  }

  // Get the value of the question
  let questionValue = (question.survey as any).getValue(question.name);

  const [updatedQuestionValue, setQuestionValue] = React.useState(null);
  //const completionDate = new Date();

  // const onCompleteClicked = () => {
  //   let newValue = questionValue || {};
  //   newValue["status"] = "Completed";
  //   newValue["completiondate"] = completionDate;
  //   (question.survey as any).setValue(question.name, newValue);
  //   setQuestionValue(newValue);
  // };

  // const onUndoClicked = () => {
  //   let newValue = questionValue || {};
  //   newValue["status"] = "Not Started";
  //   newValue["completiondate"] = null;
  //   (question.survey as any).setValue(question.name, newValue);
  //   setQuestionValue(newValue);
  // };

  useEffect(() => {
    let newValue = questionValue || {};
    setQuestionValue(newValue);
  }, []);

  let stepTypeValue = updatedQuestionValue && updatedQuestionValue["steptype"];
  let dateValue = updatedQuestionValue && updatedQuestionValue["duedate"];
  let assigneeValue = updatedQuestionValue && updatedQuestionValue["assignee"];
  let assigneeTeamValue =
    updatedQuestionValue && updatedQuestionValue["assigneeteam"];
  let currentStatus = updatedQuestionValue && updatedQuestionValue["status"];
  let localDate = isNotNullAndUndefined(dateValue)
    ? convertUTCDateToLocalTimezone(dateValue)
    : null;
  let dueDateValue = isNotNullAndUndefined(localDate)
    ? moment(localDate).format("DD/MM/YYYY")
    : null;

  let showPanel = false;
  let isDesignMode = question.survey.isDesignMode;
  if (isDesignMode === true) {
    showPanel = true;
  }
  if (
    (isNotNullAndUndefined(assigneeValue) ||
      isNotNullAndUndefined(assigneeTeamValue) ||
      isNotNullAndUndefined(dateValue)) &&
    (stepTypeValue === "Task" || stepTypeValue === "Approval")
  ) {
    showPanel = true;
  }

  const statusNameWithColor =
    isNotNullAndUndefined(currentStatus) &&
    isNotNullAndUndefined(RAFStatusNameWithColor[currentStatus])
      ? RAFStatusNameWithColor[currentStatus]
      : {
          Value: null,
          DisplayName: null,
          Color: "#333",
          LightColor: "#f0f0f0",
        };

  if (showPanel === true) {
    return (
      <div className="w-100 ps-2">
        <div className="row bp-new-header g-2 g-md-3 flex-nowrap">
          {/* {updatedQuestionValue &&
        updatedQuestionValue["status"] !== "Completed" ? (
          <div className="col-auto bp-new-header__order">
            {currentPageNumber}
          </div>
        ) : (
          <div className="col-auto bp-new-header__checkIcon">
            <div className="ps-md-2">
              <i className={"fas fa-circle-check text__Green1"}></i>
            </div>
          </div>
        )} */}
          <div
            className={"col bp-new-header__title bp-new-header__titleHeight"}
          >
            <div className="w-100 overflowX-hidden">
              <div className="row gx-2 gy-0">
                <div className="col">
                  <div className="row gy-1 gx-0 align-items-center">
                    <div className="col-12">
                      <div className="body_3_light">
                        <span>Status:</span>
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center">
                      <span
                        className="raf_badge raf_xsm ecllipseFirstline"
                        style={{
                          backgroundColor: isNotNullAndUndefined(
                            statusNameWithColor
                          )
                            ? hexToRGBA(statusNameWithColor.Color, 0.1)
                            : "#333",
                          color: statusNameWithColor.Color,
                          border: `1px solid ${hexToRGBA(
                            statusNameWithColor.Color,
                            0.5
                          )}`,
                        }}
                      >
                        {IsNotNullOrWhiteSpace(statusNameWithColor)
                          ? statusNameWithColor.DisplayName
                          : IsNotNullOrWhiteSpace(currentStatus)
                          ? currentStatus
                          : "Not set"}
                      </span>
                      {/* <span className="ecllipseFirstLine body_1">
                        {isNotNullAndUndefined(updatedQuestionValue) &&
                        isNotNullAndUndefined(updatedQuestionValue["status"])
                          ? updatedQuestionValue["status"]
                          : "Not set"}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row gy-1 gx-0 align-items-center">
                    <div className="col-12">
                      <div className="body_3_light">
                        {/* <span className="fas fa-user pe-2"></span> */}
                        <span>Assignee:</span>
                      </div>
                    </div>
                    <div className="col-12">
                      <span className="ecllipseFirstLine body_2">
                        {isNotNullAndUndefined(updatedQuestionValue) &&
                        isNotNullAndUndefined(updatedQuestionValue["assignee"])
                          ? updatedQuestionValue["assignee"]
                          : isNotNullAndUndefined(updatedQuestionValue) &&
                            isNotNullAndUndefined(
                              updatedQuestionValue["assigneeteam"]
                            )
                          ? updatedQuestionValue["assigneeteam"]
                          : "Not set"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row gy-1 gx-0 align-items-center">
                    <div className="col-12">
                      <div className="body_3_light">
                        {/* <span className="fas fa-calendar-days pe-2 e-btn-icon"></span> */}
                        <span>Due on:</span>
                      </div>
                    </div>
                    <div className="col-12">
                      <span className="ecllipseFirstLine body_2">
                        {isNotNullAndUndefined(dueDateValue)
                          ? dueDateValue
                          : "Not set"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row g-2 align-items-center">
            <div className="col d-flex flex-column">
              <div className="row gx-2 align-items-center">
                <div className="col d-flex align-items-center">
                  <div className="userProfile">
                    <span className="e-btn-icon fas fa-user"></span>
                  </div>
                  <span className="ecllipseFirstLine">
                    {isNotNullAndUndefined(updatedQuestionValue) &&
                    isNotNullAndUndefined(updatedQuestionValue["assignee"])
                      ? updatedQuestionValue["assignee"]
                      : "Not set"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col d-flex flex-column">
              <div className="row gx-2 align-items-center">
                <div className="col d-flex align-items-center">
                  <div className="userProfile">
                    <span className="fas fa-calendar-days pe-1 e-btn-icon"></span>
                    <span className="pe-1">Due on:</span>
                  </div>
                  <span className="ecllipseFirstLine">
                    {isNotNullAndUndefined(dueDateValue)
                      ? dueDateValue
                      : "Not set"}
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          </div>
          {/* <div className="col-auto row g-2 flex-nowrap">
          {updatedQuestionValue &&
          updatedQuestionValue["status"] === "Not Started" ? (
            <div className="col-auto d-flex align-items-center">
              <ButtonComponent
                type="button"
                className="custom-button-sm e-outline e-success"
                id={`btn_Bp_running_${RAFButtonConstant.MarkAsDone.Id}`}
                onClick={onCompleteClicked.bind(this)}
              >
                {RAFButtonConstant.MarkAsDone.DisplayName}
              </ButtonComponent>
            </div>
          ) : updatedQuestionValue &&
            updatedQuestionValue["status"] === "Completed" ? (
            <div className="col-auto d-flex align-items-center">
              <ButtonComponent
                type="button"
                className="custom-button-sm e-outline e-success"
                id={`btn_Bp_running_undo`}
                onClick={onUndoClicked.bind(this)}
              >
                Undo
              </ButtonComponent>
            </div>
          ) : (
            <></>
          )}
        </div> */}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default RAFCustomProcessStepRenderer;
