
import { Reducer } from "react";

import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../../../../../RAFComponents/helpers/utils";
import "./FilePreviewComponent.css";

import { DialogComponent } from "@syncfusion/ej2-react-popups";
//import RAFProfilePhotoImageEditor from "../../../../RAFComponents/Inputs/RAFProfilePhotoImageEditor";
import React from "react";
import { PreventFocusOnDialogOpen } from "../../../../../RAFComponents/Dialog/SFDialogUtils";
import RiskMatrixJsonUI from "../../../../../RAFComponents/Inputs/RAFRiskMatrix/RiskMatrixJsonUI";
import { RiskScoreTransValueRow } from "../../../../../RAFComponents/Inputs/RAFRiskMatrix/RiskScoreTransRow";
import { riskMatrixDocumentNew } from "../../../../../RAFComponents/Inputs/RAFRiskMatrix/dataNew";
import "../../../../../RAFComponents/Inputs/img_card_style.scss";
import * as repositoryActions from "../../../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../../../constants/Common/Constants";

interface IState {
  riskMatrixContentVisiblity: boolean;
}

const RAFRiskMatrixComponent = ({ question }) => {

  const selectedRiskValue: RiskScoreTransValueRow = question.value !== null && question.value !== undefined ? question.value : null;

  let displayMode = false;
  if (
    isNotNullAndUndefined(question) &&
    isNotNullAndUndefined(question.survey) &&
    isNotNullAndUndefined(question.survey.mode) &&
    question.survey.mode === "display"
  ) {
    displayMode = true;
  }

  const [state, setState] = React.useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      riskMatrixContentVisiblity: false,
    }
  );

  const RiskMatrixById = (id) => {
    return new Promise((resolve, reject) => {
      repositoryActions
        .postDataAndGetResponse(
          "RiskMatrix/Retrieve",
          { EntityId: id },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entity);
          }
        })
        .catch((error) => error);
    });
  };

  const GetRiskMatrix = () => {
    return new Promise((resolve, reject) => {
      repositoryActions
        .getData("RiskMatrix/Get", null, null, ContentType.applicationJson)
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entity);
          }
        })
        .catch((error) => error);
    });
  };

  //view risk Score dialog starts
  const riskScoreDialogOpen = () => {
    if (displayMode) return;
    setState({ riskMatrixContentVisiblity: true });
  };

  const riskScoreDialogClose = () => {
    setState({ riskMatrixContentVisiblity: false });
  };

  const riskScoreDialogContent = () => {
    if (state.riskMatrixContentVisiblity === true) {
      const selectedRiskScore = isNotNullAndUndefined(selectedRiskValue)
        ? selectedRiskValue.Score
        : null;

      return (
        <RiskMatrixJsonUI
          riskMatrixJsonRow={riskMatrixDocumentNew}
          selectedRiskScore={selectedRiskScore}
          onClearRiskScoreMatrix={clearRiskScoreMatrix}
          setCellData={setCellData}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const setCellData = (riskValue: RiskScoreTransValueRow) => {
    setState({ riskMatrixContentVisiblity: false });
    question.value = riskValue;
  };

  const clearRiskScoreMatrix = () => {
    question.value = null;
  };
  //view risk Score dialog ends

  const levelBGColor =
    isNotNullAndUndefined(selectedRiskValue) ?
      selectedRiskValue.Color
      : null;

  const riskScore = isNotNullAndUndefined(selectedRiskValue)
    ? selectedRiskValue.Score
    : null;

  const levelName = isNotNullAndUndefined(selectedRiskValue)
    ? selectedRiskValue.LevelName
    : null;

  return (
    <div>
      <div className={"e-input-group g-0 row with-padding"}>
        <div className="col">
          <div
            className="e-input-in-wrap risk_matrix_input"
            style={{
              backgroundColor: isNotNullAndUndefined(levelBGColor) ? levelBGColor : "#cccccc",
              color: "#000",
            }}
          >
            <div className="ps-3">
              {IsNotNullOrWhiteSpace(riskScore) ? `${riskScore} - ${levelName}` : null}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <span
            className="e-input-group-icon fas fa-exclamation-triangle"
            onClick={() => riskScoreDialogOpen()}
          ></span>
        </div>
      </div>
      {state.riskMatrixContentVisiblity && (
        <DialogComponent
          header="Select Risk Score"
          showCloseIcon
          visible={state.riskMatrixContentVisiblity}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          content={riskScoreDialogContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={riskScoreDialogClose.bind(this)}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
        />
      )}
    </div>
  );
};

export default React.memo(RAFRiskMatrixComponent);
