import React from "react";
import {
  isNotNullAndUndefined,
  isNotEmptyArray,
  IsNotNullOrWhiteSpace,
} from "../../../../../RAFComponents/helpers/utils";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const RAFStarRatingComponent = ({ question }) => {
  const [hoverActiveValue, setHoverActiveValue] = React.useState(null);
  const [questionValue, setQuestionValue] = React.useState(question.value);
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  const questionJsonObj = question["jsonObj"];
  const choiceOptions = questionJsonObj["rateValues"];

  const onChangeInput = (itemIndex, input) => {
    let value = null;
    if (
      isNotEmptyArray(choiceOptions) &&
      isNotNullAndUndefined(itemIndex) &&
      itemIndex > 0
    ) {
      const selectedItem = choiceOptions[itemIndex - 1];
      value = isNotNullAndUndefined(selectedItem) ? selectedItem.text : null;
    }
    setQuestionValue(value);
    input.value = value;
  };

  const onChangeActive = (event, itemIndex) => {
    if (
      itemIndex > 0 &&
      isNotNullAndUndefined(itemIndex) &&
      isNotEmptyArray(choiceOptions)
    ) {
      const hoverItem = choiceOptions[itemIndex - 1];
      const displayLabel = isNotNullAndUndefined(hoverItem)
        ? hoverItem.text
        : null;
      setHoverActiveValue(displayLabel);
    } else {
      setHoverActiveValue(null);
    }
  };

  const getRatingValueIndex = (text) => {
    let index = null;
    if (isNotNullAndUndefined(text) && isNotEmptyArray(choiceOptions)) {
      const itemIndex = choiceOptions.findIndex((item) => item.text === text);
      if (itemIndex !== -1) {
        index = itemIndex + 1;
      } else {
        index = null;
      }
    }
    return index;
  };

  function getLabelText(value: number) {
    const hoverItem = isNotNullAndUndefined(value)
      ? choiceOptions[value]
      : null;
    const displayLabel = isNotNullAndUndefined(hoverItem)
      ? hoverItem.text
      : null;
    return `${value} Star${value !== 1 ? "s" : ""}, ${displayLabel}`;
  }

  return (
    <div className="col-12">
      <div className="d-flex align-items-center flex-wrap">
        <Rating
          name={question.name}
          value={getRatingValueIndex(questionValue)}
          precision={1}
          getLabelText={getLabelText}
          onChange={(e, newValue) => {
            if (e.isTrusted) {
              onChangeInput(newValue, question);
            }
          }}
          onChangeActive={(event, newHover) => {
            onChangeActive(event, newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          {...(isNotEmptyArray(choiceOptions)
            ? {
                max: choiceOptions.length,
              }
            : {})}
          disabled={disabled}
        />
        <div>
          {IsNotNullOrWhiteSpace(hoverActiveValue)
            ? hoverActiveValue
            : questionValue}
        </div>
      </div>
    </div>
  );
};

export default RAFStarRatingComponent;
