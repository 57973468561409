import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { SurveyElementBase } from "survey-react-ui";
import {
  getFileExtension,
  downloadFile,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import "./FilePreviewComponent.css";
import FileThumbnailComponent from "./FileThumbnailComponent";

export class FilePreviewComponent extends SurveyElementBase<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      question: props.question,
    };
  }
  downloadFileClicked = (fileItem) => {
    if (!fileItem.content) {
      //download file from server
      downloadFile(fileItem.UID, fileItem.Value);
    } else {
      fetch(fileItem.content)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], fileItem.name, {
            type: fileItem.type,
          });
          const reader = new FileReader();
          reader.onload = (e) => {
            const a = document.createElement("a");
            a.href = e.target.result as string;
            a.download = fileItem.name;
            a.click();
          };
          reader.readAsDataURL(file);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  render() {
    const { question } = this.state;
    if (!question || !question.value) {
      return null;
    }
    return (
      <>
        <div className="file-sd-container">
          {question.value.map((fileItem, index) => {
            let fileName = isNotNullAndUndefined(fileItem.name)
              ? fileItem.name
              : fileItem.Value;

            let fileClassname = "fiv-viv-lg fiv-viv fiv-icon-blank";
            if (fileItem.content) {
              const fileNameExtension = fileItem.name;
              const fileExtension = getFileExtension(fileNameExtension);
              if (isNotNullAndUndefined(fileExtension)) {
                fileClassname =
                  "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
              }
            }
            return (
              <div
                key={fileName + "_" + index}
                className="sd-file__preview-item"
              >
                <div className="sd-file__image-wrapper">
                  {!fileItem.content ? (
                    <FileThumbnailComponent fileItem={fileItem} index={index} />
                  ) : fileItem.type.includes("image/") ? (
                    <img src={fileItem.content} alt="File preview" />
                  ) : (
                    <div className="fiv-viv-outer">
                      <span className={fileClassname}></span>
                    </div>
                  )}
                  <div
                    className="sd-context-btn--small sd-context-btn--with-border sd-context-btn--colorful sd-context-btn--negative sd-file__remove-file-button sd-context-btn"
                    onClick={() => {
                      //this.state.question.removeFile(fileItem.name);
                      let files = this.state.question.value.filter(
                        (file) => file !== fileItem
                      );
                      this.state.question.value = files;
                    }}
                  >
                    <span className="sd-hidden">Remove this file</span>
                    <svg className="sv-svg-icon" role="img">
                      <use xlinkHref="#icon-close_16x16"></use>
                      <title>Remove this file</title>
                    </svg>
                  </div>
                </div>
                <div
                  className="sd-file__sign"
                  onClick={() => this.downloadFileClicked(fileItem)}
                >
                  <TooltipComponent title={"Download"}>
                    {" "}
                    <a>{fileName}</a>
                  </TooltipComponent>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
