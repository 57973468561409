import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React, { Fragment, useEffect } from "react";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { isArrayOfType } from "../../../../../RAFComponents/helpers/AppHelper";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import * as R from "ramda";

const RAFDropdownComponent = ({
  question,
  questionDisplayStyle,
  choiceOptions,
  otherText,
}) => {
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (
    isNotNullAndUndefined(question) &&
    isNotNullAndUndefined(question.survey) &&
    isNotNullAndUndefined(question.survey.mode) &&
    question.survey.mode === "display"
  ) {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  let questionVal = R.clone(question.value);
  const [questionValue, setQuestionValue] = React.useState(questionVal);
  let dropDownListComponent: DropDownListComponent;
  let isStringArray = isArrayOfType(choiceOptions, "string");
  let newChoiceOptions = [];
  if (isStringArray) {
    choiceOptions &&
      choiceOptions.forEach((choice) => {
        newChoiceOptions.push({
          value: choice,
          text: choice,
        });
      });
  } else {
    choiceOptions &&
      choiceOptions.forEach((choice) => {
        newChoiceOptions.push({
          value: choice.value,
          text: choice.text ?? choice.value,
          colorCode: choice.colorCode,
        });
      });
  }
  const otherChoicelistArray = [];
  if (question.showOtherItem) {
    otherChoicelistArray.push(otherText ?? "Other");
    newChoiceOptions.push({
      value: otherText ?? "Other",
      text: otherText ?? "Other",
    });
  }
  let dropdownBtnComponent: DropDownButtonComponent;
  let fields = { text: "text", value: "value" };
  let indexVal: string = Guid.newGuid();

  let listViewComponent: ListViewComponent;
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    if (isNotNullAndUndefined(listViewComponent)) {
      listViewComponent.dataSource = newChoiceOptions;
    }
  };

  const nodeTemplate = (data) => {
    if (isNotNullAndUndefined(data)) {
      const item = data;
      let backgroundColor;
      let color;
      let iconColor;
      let iconName;

      backgroundColor = item.colorCode;
      color = "white";
      iconColor = item.colorCode;

      return (
        <div
          id={`dropdown_item_${item.value}_${question.name}_${indexVal}`}
          className="dropdown_item px-3"
          style={{
            background: backgroundColor,
            color:
              isNotNullAndUndefined(color) &&
              item.colorCode !== "transparent" &&
              item.colorCode !== ""
                ? color
                : "black",
          }}
          onClick={() => {
            if (disabled) return;

            onItemClicked(
              item.text,
              item.value,
              "customBtn_" + question.name,
              item.colorCode
            );
          }}
        >
          <span className="text-truncate">{item.text}</span>
        </div>
      );
    }
  };

  const onItemClicked = (label, value, divId, colorCode) => {
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    let customDropdownBtn = document.querySelector<HTMLElement>(
      ".customBtn_" + question.name
    );
    if (isNotNullAndUndefined(customDropdownBtn)) {
      customDropdownBtn.style.background = colorCode;

      //customDropdownBtn.style.color = '#fff';
      customDropdownBtn.style.paddingLeft = "15px";
      customDropdownBtn.style.paddingRight = "10px";
      customDropdownBtn.style.fontWeight = "400";
    }

    if (label === "") {
      question.value = null;
      setQuestionValue(null);
    } else {
      question.value = label;
      setQuestionValue(label);
    }

    if (isNotNullAndUndefined(dropdownBtnComponent)) {
      //dropdownBtnComponent.content = label;

      dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-circle" style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
    }
  };

  const dropdownClick = (btnClass, containerId) => {
    let btnDiv = document.querySelector("." + btnClass);

    let containerDiv = document.getElementById(containerId);

    if (isNotNullAndUndefined(btnDiv) && isNotNullAndUndefined(containerDiv)) {
      let btnDivWidth = btnDiv.clientWidth;

      containerDiv.style.width = btnDivWidth + "px";
    }
  };

  const handleChange = (value) => {
    question.value = value;
    setQuestionValue(value);
  };

  const getDisplayInputValue = (value) => {
    let displayValue = value;
    if (IsNotNullOrWhiteSpace(value)) {
      if (isNotEmptyArray(newChoiceOptions)) {
        let itemExist = newChoiceOptions.find(
          (x) => x.value === value || x.text === value
        );
        if (
          IsNotNullOrWhiteSpace(isOtherItemExist()) &&
          isNullOrUndefined(itemExist)
        ) {
          displayValue = isOtherItemExist();
        } else {
          displayValue = value;
        }
      }
    }
    return displayValue;
  };

  function isOtherItemExist() {
    let itemValue: string = null;
    if (isNotEmptyArray(newChoiceOptions)) {
      newChoiceOptions.forEach((x) => {
        if (
          IsNotNullOrWhiteSpace(x.value) &&
          otherChoicelistArray.includes(x.value)
        ) {
          itemValue = x.value;
        }
        if (
          IsNotNullOrWhiteSpace(x.text) &&
          otherChoicelistArray.includes(x.text)
        ) {
          itemValue = x.text;
        }
      });
    }

    return itemValue;
  }

  const onChangeTextBoxInputValue = (e) => {
    if (e.isInteracted) {
      let eValue = IsNotNullOrWhiteSpace(e.value)
        ? e.value
        : isOtherItemExist();
      question.value = eValue;
      setQuestionValue(eValue);
    }
  };

  const setValueDelay = (inputValue) => {
    setTimeout(() => {
      if (dropDownListComponent) {
        dropDownListComponent.value = inputValue ?? null;
      }
    }, 100);
  };

  const inputValue = getDisplayInputValue(questionValue);
  if (questionDisplayStyle === "Default") {
    setValueDelay(inputValue);
    return (
      <Fragment>
        <div className="row g-2">
          <div className="col">
            <DropDownListComponent
              id={`ddl_${question.name}`}
              name={question.name}
              value={inputValue}
              ref={(d) => (dropDownListComponent = d)}
              change={(e) => {
                if (e.isInteracted) {
                  handleChange(
                    e.itemData !== null ? e.itemData[fields.value] : null
                  );
                }
              }}
              dataSource={newChoiceOptions as any}
              fields={fields}
              //delayUpdate
              allowFiltering={question.allowFiltering ?? false}
              //width={width}
              readonly={disabled}
              filterType="Contains"
              placeholder={question.placeholder}
              showClearButton={question.allowClear ?? false}
              //itemTemplate={isColorOption ? this.itemTemplate : null}
              //valueTemplate={isColorOption ? this.valueTemplate : null}
            />
          </div>
        </div>
        {IsNotNullOrWhiteSpace(inputValue) &&
          otherChoicelistArray.includes(inputValue) && (
            <div className="col-12 d-flex py-2">
              <div className="w-100">
                <TextBoxComponent
                  id={`ttx_${question.name}`}
                  value={
                    !otherChoicelistArray.includes(questionValue)
                      ? questionValue
                      : ""
                  }
                  name={question.name}
                  change={(e) => onChangeTextBoxInputValue(e)}
                  type="text"
                  width={"100%"}
                  showClearButton={true}
                  placeholder={question.otherPlaceholder ?? "Please specify"}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
      </Fragment>
    );
  } else if (questionDisplayStyle === "Colored") {
    return (
      <div className="col-12">
        <div className="d-flex align-items-center">
          {newChoiceOptions && newChoiceOptions.length > 4 && (
            <Fragment>
              <div className="col">
                <ListViewComponent
                  id={"drodownBtnComponent_" + question.name + "_" + indexVal}
                  ref={(g) => (listViewComponent = g)}
                  template={nodeTemplate}
                />

                <div className="d-flex">
                  <DropDownButtonComponent
                    id={`ddb_${question.name}`}
                    target={
                      "#drodownBtnComponent_" + question.name + "_" + indexVal
                    }
                    onClick={() => {
                      if (disabled) return;
                      dropdownClick(
                        "customBtn_" + question.name,
                        "drodownBtnComponent_" + question.name + "_" + indexVal
                      );
                    }}
                    style={{
                      color:
                        isNotNullAndUndefined(
                          newChoiceOptions &&
                            newChoiceOptions.find(
                              (x) => x.text === questionValue
                            ) &&
                            newChoiceOptions.find(
                              (x) => x.text === questionValue
                            ).colorCode
                        ) &&
                        newChoiceOptions.find((x) => x.text === questionValue)
                          .colorCode !== "transparent" &&
                        newChoiceOptions.find((x) => x.text === questionValue)
                          .colorCode !== ""
                          ? "white"
                          : "black",
                      background:
                        newChoiceOptions &&
                        newChoiceOptions.find(
                          (x) => x.text === questionValue
                        ) &&
                        newChoiceOptions.find((x) => x.text === questionValue)
                          .colorCode,
                      borderColor:
                        isNotNullAndUndefined(
                          newChoiceOptions &&
                            newChoiceOptions.find(
                              (x) => x.text === questionValue
                            ) &&
                            newChoiceOptions.find(
                              (x) => x.text === questionValue
                            ).colorCode
                        ) &&
                        newChoiceOptions.find((x) => x.text === questionValue)
                          .colorCode !== "transparent" &&
                        newChoiceOptions.find((x) => x.text === questionValue)
                          .colorCode !== ""
                          ? "white"
                          : "rgba(0, 0, 0, 0.23)",
                      fontWeight: 400,
                    }}
                    cssClass={`customDropdownBtn customBtn_${question.name} custom-button-lg"}`}
                  >
                    <span className="ecllipseFirstLine pe-3 word-break-all">
                      {questionValue}
                    </span>
                  </DropDownButtonComponent>
                </div>
              </div>
            </Fragment>
          )}
          {newChoiceOptions && newChoiceOptions.length <= 4 && (
            <Fragment>
              <div className="col-12">
                <div className="row g-2 flex-wrap">
                  {newChoiceOptions.map((item, index1) => {
                    return (
                      <div className="col-auto" key={`${item.value}${index1}`}>
                        <div
                          id={`dropdown_item_${item.value}_${question.name}_${indexVal}`}
                          className={
                            questionValue === item.text
                              ? "custom-dropdown-btn active"
                              : "col custom-dropdown-btn"
                          }
                          style={{
                            background: item.colorCode,
                            color:
                              isNotNullAndUndefined(item.colorCode) &&
                              item.colorCode !== "transparent" &&
                              item.colorCode !== ""
                                ? "white"
                                : "black",
                          }}
                          onClick={() => {
                            if (disabled) return;

                            onItemClicked(
                              item.text,
                              item.value,
                              "customBtn_" + question.name,
                              item.colorCode
                            );
                          }}
                        >
                          <span className="w-100 px-2">{item.text}</span>
                          {questionValue === item.text ? (
                            <span className="fas fa-check"></span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
};

export default RAFDropdownComponent;
