import React from "react";

import { Question } from "survey-core";

import RAFDropdownComponent from "./RAFDropdownComponent";

interface RAFCustomDropdownQuestionRendererProps {
  question?: Question;
  customChoices?: any[]; //do not remove this, it is used in the parent component
}

const RAFCustomDropdownQuestionRenderer: React.FC<
  RAFCustomDropdownQuestionRendererProps
> = ({
  question,
  //, customChoices
}) => {
  let displayMode = false;
  if ((question.survey as any).mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (displayMode === true) {
    disabled = true;
  }

  const customChoices = question?.customChoices || [];
  const otherText = question?.otherText || "Other";
  const questionDisplayStyle = question?.displayStyle || "Default";

  return (
    <div>
      <RAFDropdownComponent
        question={question}
        questionDisplayStyle={questionDisplayStyle}
        choiceOptions={customChoices}
        otherText={otherText}
      />
    </div>
  );
};

export default RAFCustomDropdownQuestionRenderer;
