import { Fragment, useEffect, useRef, useState } from "react";
import CustomCardWidget from "../../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import {
  getFileExtension,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { RAFButtonConstant } from "../../../../../constants/Common/Constants";
import { downloadAndGetPredifinedURL } from "../../../../helpers/RMutils";
import { getFileSize } from "../../../../../RAFModules/ActiveContacts/Document/Library/DocumentHelper";
import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import React from "react";
import { RafClearDialogContent } from "../../../../../RAFComponents/helpers/AppHelper";

interface RAFThumbnailComponentProps {
  fileItem?: any;
  index?: number;
  removeAttachment?: (index) => void;
  displayMode?: boolean;
}

const RAFThumbnailComponent: React.FC<RAFThumbnailComponentProps> = ({
  fileItem,
  index,
  removeAttachment,
  displayMode,
}) => {
  const [fileURL, setFileURL] = useState(null);
  const [downloadFileURL, setDownloadFileURL] = useState(null);
  const [fileSize, setFileSize] = useState(0);
  const [fileClassName, setFileClassName] = useState(
    "fiv-viv-lg fiv-viv fiv-icon-blank"
  );
  const [showViewAttachmentContent, setViewAttachmentContent] = useState(false);
  const imageFileAttachmentRef = useRef<DialogComponent>(null);

  useEffect(() => {
    if (!fileItem.content) {
      let recordIds = [fileItem.UID];
      downloadAndGetPredifinedURL(recordIds).then((fileUrls) => {
        if (isNotEmptyArray(fileUrls)) {
          let thumbnailFile = fileUrls.find(
            (url) =>
              fileItem.UID === url.EntityId && url.DocumentType === "Thumbnail"
          );
          let originalFile = fileUrls.find(
            (url) =>
              fileItem.UID === url.EntityId && url.DocumentType === "File"
          );

          if (isNotNullAndUndefined(originalFile)) {
            let fileSize = getFileSize(originalFile.FileSize);
            setFileSize(fileSize);
            setDownloadFileURL(originalFile);
          }
          if (isNotNullAndUndefined(thumbnailFile)) {
            if (thumbnailFile.MimeType.startsWith("image/")) {
              setFileURL(thumbnailFile.UploadURL);
            }
          } else {
            setFileURL(null);
            setFileClassName(
              "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" +
                getFileExtension(fileItem.Value)
            );
          }
        } else {
          setFileClassName(
            "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" +
              getFileExtension(fileItem.Value)
          );
          setFileURL(null);
        }
      });
    } else {
      setFileClassName(
        "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" +
          getFileExtension(fileItem.Value)
      );
      setFileURL(null);
    }
  }, [fileItem]);

  const downloadFile = () => {
    fetch(downloadFileURL.UploadURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = downloadFileURL.ObjectName; // or any other filename
        a.click();
      });
  };

  //view preview of attachment starts
  const onclickPreviewFile = () => {
    setViewAttachmentContent(true);
  };

  const viewAttachmentDlgContent = () => {
    if (showViewAttachmentContent) {
      return (
        <div>
          <CustomCardWidget cardClassName="img_card" removeContentPadding>
            <div
              className="img_wrapper raf-h-xl"
              style={{
                backgroundImage: `url(${downloadFileURL.UploadURL})`,
              }}
            ></div>
          </CustomCardWidget>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeViewAttachmentDlg = async () => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setViewAttachmentContent(false);
  };
  //view preview of attachment ends

  return (
    <Fragment>
      <div className="col-12">
        <CustomCardWidget cardClassName="img_card" removeContentPadding>
          <div
            className="img_wrapper"
            style={{
              backgroundImage: isNotNullAndUndefined(fileURL)
                ? `url(${fileURL})`
                : `none`,
            }}
          >
            {isNotNullAndUndefined(fileURL) ? (
              <></>
            ) : (
              <div className="fiv-viv-outer">
                <span className={fileClassName}></span>
              </div>
            )}
            <div className="img_card_btn_container">
              {downloadFileURL &&
                downloadFileURL.MimeType.startsWith("image/") && (
                  <RAFButtonComponent
                    className="e-round"
                    onClick={() => {
                      onclickPreviewFile();
                    }}
                    action={RAFButtonConstant.Preview}
                    iconBtn
                  ></RAFButtonComponent>
                )}
              {/* <RAFButtonComponent
                            className="e-round"
                            onClick={() => {
                              onclickEditAttachment(file);
                            }}
                            action={RAFButtonConstant.Edit}
                            iconBtn
                          ></RAFButtonComponent> */}
              {displayMode === false && (
                <RAFButtonComponent
                  className="e-round"
                  onClick={() => {
                    removeAttachment(index);
                  }}
                  action={RAFButtonConstant.Delete}
                  iconBtn
                ></RAFButtonComponent>
              )}
            </div>
          </div>
        </CustomCardWidget>
      </div>
      <div className="col-12">
        <div className="img_card_text">
          <div
            className="header-text header-text-light text-truncate pointer"
            onClick={downloadFile}
          >
            <TooltipComponent title={"Download"}>
              {downloadFileURL && downloadFileURL.ObjectName}
            </TooltipComponent>
          </div>
          <div className="secondary-header-text">{fileSize}</div>
        </div>
      </div>
      {showViewAttachmentContent && (
        <DialogComponent
          header={"View Attachment"}
          cssClass="centerDialog-md fixed-header"
          visible={showViewAttachmentContent}
          content={viewAttachmentDlgContent.bind(this)}
          isModal
          target="#root"
          closeOnEscape
          close={closeViewAttachmentDlg.bind(this)}
          overlayClick={closeViewAttachmentDlg.bind(this)}
          showCloseIcon={true}
          ref={imageFileAttachmentRef}
        />
      )}
    </Fragment>
  );
};

export default RAFThumbnailComponent;
