import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "@syncfusion/ej2-base";
import React, { Reducer, useEffect, useReducer } from "react";
import {
  getLookUpItems,
  getSearchedLookUpItems,
  getUniqueFilteredLookUpDropdownItems,
} from "../../../../../RAFComponents/helpers/AutoCompleteMUIHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { Constants } from "../../../../../constants/Common/Constants";

const defaultTake = 50;
const defaultSkip = 0;

interface IState {
  isLoading: boolean;
  dropdownState: boolean;
  dataSource: LookUpRow[];
  searchedText: string;
  prevSearchedText: string;
  skipItemsOnScroll: number;
}

const filter = createFilterOptions();

const RAFAutoCompleteLookupMUIComponent = ({ question }) => {
  let isReadOnly = question["readOnly"];
  let survey = question.survey;
  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }

  const apiUrl = Constants.baseAPIUrl + question.customurl;
  const moduleName = question.moduleName;
  const customFilter = question.customFilter;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: false,
      dropdownState: false,
      dataSource: [],
      searchedText: null,
      prevSearchedText: null,
      skipItemsOnScroll: 0,
    }
  );

  useEffect(() => {
    getLookUpDropDownItems();
  }, []);

  const onOpenAutoDropdown = () => {
    setState({ dropdownState: true, skipItemsOnScroll: 0 });
    getLookUpDropDownItems();
  };

  const getLookUpDropDownItems = async () => {
    if (isNotEmptyArray(state.dataSource)) {
    } else {
      if (isNotNullAndUndefined(state.searchedText)) {
        return;
      }
      setState({ isLoading: true });
      let customFilterObj = null;

      if (IsNotNullOrWhiteSpace(customFilter)) {
        try {
          customFilterObj = JSON.parse(customFilter);
        } catch (error) {
          console.error("Error parsing JSON string:", error);
        }
      }

      const lookUpDataItems = await getLookUpItems(
        apiUrl,
        defaultSkip,
        defaultTake,
        null,
        moduleName,
        customFilterObj,
        null
      );

      if (isNotEmptyArray(lookUpDataItems)) {
        const data: LookUpRow[] = lookUpDataItems;

        const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

        setState({ dataSource: uniqueData, isLoading: false });
      } else {
        setState({ dropdownState: false, dataSource: [], isLoading: false });
      }
    }
  };

  const handleInputChange = (event, value) => {
    //calls when text input changes
    if (
      isNotNullAndUndefined(event) &&
      event.isTrusted === true &&
      event.type === "change"
    ) {
      setHandleInputDebounce(value);
    }
  };

  const setHandleInputDebounce = debounce(async (newSearchedText) => {
    setState({ isLoading: true, skipItemsOnScroll: defaultSkip });
    const searchedText = newSearchedText;
    let customFilterObj = null;

    if (IsNotNullOrWhiteSpace(customFilter)) {
      try {
        customFilterObj = JSON.parse(customFilter);
      } catch (error) {
        console.error("Error parsing JSON string:", error);
      }
    }
    const responseLookUpItems = await getSearchedLookUpItems(
      apiUrl,
      defaultSkip,
      defaultTake,
      searchedText,
      moduleName,
      customFilterObj,
      state.dataSource,
      state.prevSearchedText
    );

    const lookUpItems = isNotNullAndUndefined(responseLookUpItems)
      ? responseLookUpItems.lookUpDataItems
      : [];

    if (isNotEmptyArray(lookUpItems)) {
      const data: LookUpRow[] = [...lookUpItems];

      const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

      setState({
        dataSource: uniqueData,
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else {
      setState({
        dropdownState: false,
        dataSource: [],
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    }
  }, 500);

  const onChange = (
    newValue: LookUpRow,
    input: string,
    newCreateValue?: string
  ) => {
    const itemUID = isNotNullAndUndefined(newValue) ? newValue.UID : null;
    const itemValue = isNotNullAndUndefined(newValue) ? newValue.Value : null;
    if (question.isPropertyEditor) {
      //only for property editor set lookup value in question.value
      question.value = { UID: itemUID, Value: itemValue };
    } else {
      survey.setValue([input + "uid"], itemUID);
      survey.setValue(input, itemValue);
      //setQuestionValue(itemValue !== null ? itemValue : null);
    }

    if (IsNotNullOrWhiteSpace(newCreateValue)) {
      setState({ searchedText: newCreateValue });
    }
  };

  const loadMoreOptions = async () => {
    const { skipItemsOnScroll, searchedText, dataSource } = state;
    const newSkipItemsOnScroll = skipItemsOnScroll + defaultTake;
    if (
      isNotEmptyArray(dataSource) &&
      newSkipItemsOnScroll > dataSource.length
    ) {
      return;
    }
    let customFilterObj = null;

    if (IsNotNullOrWhiteSpace(customFilter)) {
      try {
        customFilterObj = JSON.parse(customFilter);
      } catch (error) {
        console.error("Error parsing JSON string:", error);
      }
    }
    const responseLookUpItems = await getSearchedLookUpItems(
      apiUrl,
      newSkipItemsOnScroll,
      defaultTake,
      searchedText,
      moduleName,
      customFilterObj,
      state.dataSource,
      state.prevSearchedText
    );

    const lookUpItems = isNotNullAndUndefined(responseLookUpItems)
      ? responseLookUpItems.lookUpDataItems
      : [];

    if (isNotEmptyArray(lookUpItems)) {
      const responseData: LookUpRow[] = isNotEmptyArray(lookUpItems)
        ? [...lookUpItems]
        : [];

      const data = [...dataSource, ...responseData];

      const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dataSource: uniqueData,
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else {
      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dropdownState: false,
        dataSource: [],
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    }
  };

  const onCloseAutoDropdown = () => {
    setState({
      skipItemsOnScroll: 0,
      dropdownState: false,
      isLoading: false,
      searchedText: null,
      prevSearchedText: null,
      dataSource: IsNotNullOrWhiteSpace(state.searchedText)
        ? []
        : state.dataSource,
    });
  };

  return (
    <div className="col-12">
      <Autocomplete
        id={question.name}
        value={question.value}
        open={state.dropdownState}
        disabled={disabled}
        onOpen={() => {
          onOpenAutoDropdown();
        }}
        onClose={() => {
          onCloseAutoDropdown();
        }}
        options={state.dataSource}
        isOptionEqualToValue={(option, value) => option.Value === value}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.Value ? option.Value : "";
        }}
        getOptionKey={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.UID ? option.UID : "";
        }}
        //   className={`raf_mui_input${showFullList === true ? " left_icon" : ""
        // }`}
        loading={state.isLoading}
        onInputChange={handleInputChange}
        onBlur={(event) => {
          if (isNotNullAndUndefined(state.searchedText)) {
            onCloseAutoDropdown();
          }
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            //on enter key press
            const existingItem =
              IsNotNullOrWhiteSpace(newValue) &&
              isNotEmptyArray(state.dataSource)
                ? state.dataSource.find(
                    (x) =>
                      IsNullOrWhiteSpace(x["inputValue"]) &&
                      isNotNullAndUndefined(x.Value) &&
                      x.Value.toLowerCase() === newValue.toLowerCase()
                  )
                : null;

            if (isNotNullAndUndefined(existingItem)) {
              onChange(existingItem, question.name);
            } else {
              onChange(null, question.name, newValue);
            }
          } else if (
            //allowCreateEmptyItem === true &&
            isNotNullAndUndefined(newValue) &&
            IsNotNullOrWhiteSpace(newValue.inputValue)
          ) {
            onChange(null, question.name, newValue.inputValue);
          } else {
            onChange(newValue, question.name);
          }
        }}
        size="small"
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          return filtered;
        }}
        freeSolo
        clearOnBlur
        renderOption={(props, option) => <li {...props}>{option.Value}</li>}
        autoFocus={false}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={props.placeholder}
            margin="none"
            variant="outlined"
            //placeholder={props.placeholder}

            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {state.isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disabled={disabled}
          />
        )}
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;

            let scrollHeight = Math.round(listboxNode.scrollHeight);
            let clientHeight = Math.round(
              listboxNode.scrollTop + listboxNode.clientHeight
            );

            if (clientHeight === scrollHeight) {
              // The scroll has hit the bottom, load more options
              loadMoreOptions();
            }
          },
        }}
      />
    </div>
  );
};

export default RAFAutoCompleteLookupMUIComponent;
