import { UploaderComponent } from "@syncfusion/ej2-react-inputs";

import { select } from "@syncfusion/ej2-base";
import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import RAFProfilePhotoImageEditor from "../../../../../RAFComponents/Inputs/RAFProfilePhotoImageEditor";
import CustomCardWidget from "../../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFIconImage from "../../../../../RAFComponents/Navigation/RAFIconImage";
import { RafClearDialogContent } from "../../../../../RAFComponents/helpers/AppHelper";
import {
  ConvertToElementID,
  Guid,
  getFileExtension,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import {
  getCompressedImage,
  getFileSize,
  getResizedImage,
} from "../../../../../RAFModules/ActiveContacts/Document/Library/DocumentHelper";
import {
  Constants,
  RAFButtonConstant,
} from "../../../../../constants/Common/Constants";
import "./FilePreviewComponent.css";
import RAFThumbnailComponent from "./RAFThumbnailComponent";

const RAFImagePickerComponent = ({ question }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedThumbnailFiles, setSelectedThumbnailFiles] = useState([]);
  const [showViewAttachmentContent, setViewAttachmentContent] = useState(false);
  const [showAttachmentEditorContent, setAttachmentEditorContent] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  useEffect(() => {
    if (question.value !== null && question.value !== undefined) {
      setSelectedFiles(question.value);
      setSelectedThumbnailFiles(question.value);
    }
  }, []);

  const imageFileAttachmentRef = useRef<DialogComponent>(null);

  let uploadObj: UploaderComponent;

  const onUploadSuccess = (args) => {
    // Handle successful upload
  };

  const onUploadRemoving = (args) => {
    // Handle file removal
  };

  const asyncSettings: object = {
    saveUrl: `${Constants.baseAPIUrl}ContentLibrary/Upload`,
  };

  const allowedExtensions = "image/*; capture=camera"; //".jpg,.png,.jpeg";

  const indexVal = Guid.newGuid();
  const attachmentDivID = ConvertToElementID(question.name + "_" + indexVal);

  const onClickUploadPhoto = () => {
    let browsebtn = document.getElementById(`browse_${attachmentDivID}`);
    if (isNotNullAndUndefined(browsebtn)) {
      const wrapperEle: HTMLElement = select(
        `#browse_${attachmentDivID} .e-file-select-wrap button`,
        document
      ) as HTMLElement;
      wrapperEle.click();
    }
  };

  const generateGUID = () => {
    return Guid.newGuid();
  };
  const onFileSelected = async (args) => {
    const currentDateTime = moment(new Date()).format("YYYYMMDDHHmmss");

    let filesToAdd = [];
    for (let i = 0; i < args.filesData.length; i++) {
      const fileUID = generateGUID();
      filesToAdd.push({ UID: fileUID, file: args.filesData[i] });
    }

    const newFiles = filesToAdd.map(async (fileData, index) => {
      const file = fileData.file;
      const fileUID = fileData.UID;
      const rawFile = file.rawFile;
      if (rawFile.type.startsWith("image/")) {
        let file = await getCompressedImage(rawFile);

        let fileName = rawFile.name;
        const extension = fileName.split(".").pop();
        fileName = fileName.replace(`.${extension}`, "");
        const newName = `photo_${currentDateTime}_${fileName}.${extension}`;

        const content = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
        return {
          name: newName,
          size: file.size,
          content,
          type: file.type,
          UID: fileUID,
        };
      } else {
        let fileName = rawFile.name;
        const extension = fileName.split(".").pop();
        fileName = fileName.replace(`.${extension}`, "");
        const newName = `file_${currentDateTime}_${fileName}.${extension}`;

        const fileContent = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(rawFile);
        });

        return {
          name: newName,
          size: rawFile.size,
          type: rawFile.type,
          content: fileContent,
          UID: fileUID,
        };
      }
    });
    // Filter out files that have already been uploaded

    const uniqueFiles = newFiles.filter(
      (newFile) =>
        !selectedFiles ||
        !selectedFiles.some(
          (file) =>
            file.name === newFile["name"] && file.Value === newFile["name"]
        )
    );

    //const resolvedNewFiles = await Promise.all(uniqueFiles);
    const resolvedNewFiles = await Promise.all(
      uniqueFiles.map(async (file) => {
        // Perform asynchronous operations on file
        // Return the modified file
        return file;
      })
    );

    const updatedFiles = [...(selectedFiles || []), ...resolvedNewFiles];

    const newThumbnailFiles = filesToAdd.map(async (fileData, index) => {
      const file = fileData.file;
      const fileUID = fileData.UID;
      const rawFile = file.rawFile;
      if (rawFile.type.startsWith("image/")) {
        let file = await getResizedImage(rawFile);

        let fileName = rawFile.name;
        const extension = fileName.split(".").pop();
        fileName = fileName.replace(`.${extension}`, "");
        const newName = `photo_${currentDateTime}_${fileName}.${extension}`;

        const content = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
        return {
          name: newName,
          size: file.size,
          content,
          type: file.type,
          UID: fileUID,
        };
      } else {
        let fileName = rawFile.name;
        const extension = fileName.split(".").pop();
        fileName = fileName.replace(`.${extension}`, "");
        const newName = `file_${currentDateTime}_${fileName}.${extension}`;

        const fileContent = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(rawFile);
        });

        return {
          name: newName,
          size: rawFile.size,
          type: rawFile.type,
          content: fileContent,
          UID: fileUID,
        };
      }
    });

    const uniqueThumbnailFiles = newThumbnailFiles.filter(
      (newFile) =>
        !selectedFiles ||
        !selectedFiles.some(
          (file) =>
            file.name === newFile["name"] && file.Value === newFile["name"]
        )
    );

    //const resolvedNewFiles = await Promise.all(uniqueFiles);
    const resolvedThumbnailFiles = await Promise.all(
      uniqueThumbnailFiles.map(async (file) => {
        // Perform asynchronous operations on file
        // Return the modified file
        return file;
      })
    );
    const updatedThumnailFiles = [
      ...(selectedThumbnailFiles || []),
      ...resolvedThumbnailFiles,
    ];

    //compressed images stored here
    question.value = updatedFiles;
    setSelectedFiles(updatedFiles);
    //thumbnail images stored here
    setSelectedThumbnailFiles(updatedThumnailFiles);
  };

  const removeAttachmentClicked = (fileindex: number) => {
    // copy of the selectedFiles array
    let updatedFiles = [...selectedFiles];

    // Remove the file at the given index
    updatedFiles.splice(fileindex, 1);

    let updatedThumbnailFiles = [...selectedThumbnailFiles];

    // Remove the file at the given index
    updatedThumbnailFiles.splice(fileindex, 1);

    // Update the state with the updated arrays
    setSelectedFiles(updatedFiles);
    setSelectedThumbnailFiles(updatedThumbnailFiles);
    question.value = updatedFiles;
  };

  //view preview of attachment starts
  const onclickPreviewFile = (selectedAttachment) => {
    if (isNotNullAndUndefined(selectedAttachment)) {
      setSelectedFile(selectedAttachment);
      setViewAttachmentContent(true);
    }
  };

  const viewAttachmentDlgContent = () => {
    if (showViewAttachmentContent && isNotNullAndUndefined(selectedFile)) {
      const formattedContent = `data:image/*;base64,${
        selectedFile.content.split(",")[1]
      }`;
      return (
        <div>
          <CustomCardWidget cardClassName="img_card" removeContentPadding>
            <div
              className="img_wrapper raf-h-xl"
              style={{
                backgroundImage: `url(${formattedContent})`,
              }}
            ></div>
            {/* <img src={selectedFile.content} alt={selectedFile.name} /> */}
          </CustomCardWidget>
          {/* <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="" /> */}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeViewAttachmentDlg = async () => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setViewAttachmentContent(false);
    setSelectedFile(null);
  };
  //view preview of attachment ends

  //edit attachment starts
  const onclickEditAttachment = async (file) => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setAttachmentEditorContent(true);
    setSelectedFile(file);
  };
  const uploadAttachmentEditorDlgContent = () => {
    if (showAttachmentEditorContent && isNotNullAndUndefined(selectedFile)) {
      const URL = window.URL;
      const url = URL.createObjectURL(selectedFile);

      return (
        <RAFProfilePhotoImageEditor
          srcProfilePhoto={null}
          updateProfilePhoto={updateAttachment}
          removeProfileClicked={null}
          fileName={null}
          selectCropType={"custom"}
          uploadButtonText={"photo"}
          isActive
          selectedFileName={selectedFile.name}
          selectedProfilePhoto={url}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeAttachmentEditorDlg = async () => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setAttachmentEditorContent(false);
    setSelectedFile(null);
  };
  //edit attachment ends

  const updateAttachment = async (file) => {};

  const downloadFile = (fileItem) => {
    let url;
    if (typeof fileItem.content === "string") {
      // If fileItem.content is a string, it's an image URL
      url = fileItem.content;
    } else {
      // If fileItem.content is not a string, it's an ArrayBuffer
      const blob = new Blob([fileItem.content], { type: fileItem.type });
      url = URL.createObjectURL(blob);
    }

    const a = document.createElement("a");
    a.href = url;
    a.download = fileItem.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (url.startsWith("blob:")) {
      // If the URL is a blob URL, revoke it to free memory
      URL.revokeObjectURL(url);
    }
  };

  return (
    <div>
      <div className="row g-3">
        {selectedThumbnailFiles &&
          selectedThumbnailFiles.map((item, index) => {
            let file =
              selectedFiles && selectedFiles.find((x) => x.UID === item.UID);
            let fileSize;
            if (
              isNotNullAndUndefined(file) &&
              isNotNullAndUndefined(file.size)
            ) {
              fileSize = getFileSize(file.size);
            }

            let formattedContent =
              isNotNullAndUndefined(file) &&
              isNotNullAndUndefined(file.type) &&
              file.type.includes("image/") &&
              isNotNullAndUndefined(file.content)
                ? `data:image/*;base64,${file.content.split(",")[1]}`
                : null;

            let fileName =
              isNotNullAndUndefined(file) && isNotNullAndUndefined(file.name)
                ? file.name
                : isNotNullAndUndefined(file) &&
                  isNotNullAndUndefined(file.Value)
                ? file.Value
                : null;
            let fileClassname = "fiv-viv-lg fiv-viv fiv-icon-blank";
            if (
              isNotNullAndUndefined(file) &&
              isNotNullAndUndefined(file.type) &&
              !file.type.includes("image/")
            ) {
              const fileNameExtension = file.name;
              const fileExtension = getFileExtension(fileNameExtension);
              if (isNotNullAndUndefined(fileExtension)) {
                fileClassname =
                  "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
              }
            }

            return (
              <div key={index} className="col-6 col-md-4">
                <div className="row gx-0 gy-2">
                  {isNotNullAndUndefined(file) &&
                  !file.content &&
                  isNotNullAndUndefined(file.Value) ? (
                    <RAFThumbnailComponent
                      fileItem={file}
                      index={index}
                      removeAttachment={() => removeAttachmentClicked(index)}
                      displayMode={displayMode}
                    />
                  ) : (
                    <>
                      <div className="col-12">
                        <CustomCardWidget
                          cardClassName="img_card"
                          removeContentPadding
                        >
                          <div
                            className="img_wrapper"
                            style={{
                              backgroundImage: `url(${formattedContent})`,
                            }}
                          >
                            {isNotNullAndUndefined(file) &&
                            file.type.includes("image/") ? (
                              <></>
                            ) : (
                              <div className="fiv-viv-outer">
                                <span className={fileClassname}></span>
                              </div>
                            )}
                            <div className="img_card_btn_container">
                              {isNotNullAndUndefined(file) &&
                                file.type.includes("image/") && (
                                  <RAFButtonComponent
                                    className="e-round"
                                    onClick={() => {
                                      onclickPreviewFile(file);
                                    }}
                                    action={RAFButtonConstant.Preview}
                                    iconBtn
                                  ></RAFButtonComponent>
                                )}

                              <RAFButtonComponent
                                className="e-round"
                                onClick={() => {
                                  removeAttachmentClicked(index);
                                }}
                                action={RAFButtonConstant.Delete}
                                iconBtn
                              ></RAFButtonComponent>
                            </div>
                          </div>
                        </CustomCardWidget>
                      </div>
                      <div className="col-12">
                        <div className="img_card_text">
                          <div
                            className="header-text header-text-light text-truncate pointer"
                            onClick={() => downloadFile(file)}
                          >
                            <TooltipComponent title={"Download"}>
                              {fileName}
                            </TooltipComponent>
                          </div>
                          <div className="d-flex">
                            <div className="secondary-header-text me-2">
                              {fileSize}
                            </div>
                            <div className="raf_badge border-0 raf_sm raf_badge_Active">
                              <span className="raf_badge_text">New</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}

        <div className="col-6 col-md-4">
          <CustomCardWidget
            cardClassName="img_card pointer"
            removeContentPadding
            onClick={() => {
              if (disabled) return;
              onClickUploadPhoto();
            }}
          >
            <div className="img_wrapper flex-column">
              <RAFIconImage
                iconCssClass="fa-icon fa-Camera"
                moduleavatar="border-avatar"
                iconSize="48"
                fontSize="20"
              ></RAFIconImage>
              <div className="header-text header-text-light mt-2">
                Select Photo
              </div>
              <div id={`browse_${attachmentDivID}`} className="hidden">
                <UploaderComponent
                  ref={(dialog) => (uploadObj = dialog)}
                  type="file"
                  asyncSettings={asyncSettings}
                  success={onUploadSuccess}
                  removing={onUploadRemoving}
                  minFileSize={1}
                  multiple={true}
                  autoUpload={false}
                  allowedExtensions={allowedExtensions}
                  selected={onFileSelected}
                />
              </div>
            </div>
          </CustomCardWidget>
        </div>
      </div>
      {showAttachmentEditorContent && (
        <div className="e-img-editor-profile" id='id="profile-dialog"'>
          <DialogComponent
            header={"Update Attachment"}
            cssClass="centerDialog-md centerDialog-height-lg fixed-header dlg-new-style image_editor_dialog"
            visible={showAttachmentEditorContent}
            content={uploadAttachmentEditorDlgContent.bind(this)}
            isModal
            target="#root"
            id="profile-dialog"
            closeOnEscape
            close={closeAttachmentEditorDlg.bind(this)}
            overlayClick={closeAttachmentEditorDlg.bind(this)}
            showCloseIcon={true}
            ref={imageFileAttachmentRef}
          />
        </div>
      )}
      {showViewAttachmentContent && (
        <DialogComponent
          header={"View Attachment"}
          cssClass="centerDialog-md fixed-header"
          visible={showViewAttachmentContent}
          content={viewAttachmentDlgContent.bind(this)}
          isModal
          target="#root"
          closeOnEscape
          close={closeViewAttachmentDlg.bind(this)}
          overlayClick={closeViewAttachmentDlg.bind(this)}
          showCloseIcon={true}
          ref={imageFileAttachmentRef}
        />
      )}
    </div>
  );
};

export default React.memo(RAFImagePickerComponent);
