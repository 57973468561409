import React from "react";
import CustomCardWidget from "../../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFCollapseWithPlusIcon from "../../../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import { RAFLayout } from "../../../../../constants/Common/Constants";

const RAFTextAreaComponent = ({ question }) => {
  let isReadOnly = question["readOnly"];
  const [lastValue, setLastValue] = React.useState(question.value);
  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }

  const isCollapsible = question["isCollapsible"];

  const handleContentChange = React.useCallback(
    (value) => {
      if (value !== lastValue) {
        question.value = value;

        setLastValue(value);
      }
    },
    [question, lastValue]
  );
  return (
    <div className="col-12">
      {isCollapsible ? (
        <CustomCardWidget removeContentPadding>
          <RAFCollapseWithPlusIcon
            toggleArrowIcon="Arrow"
            allowFullRowClick
            title={question.title}
            showSeparator={false}
            collapsePanelHeaderClass="collapsePanel__header__fixedHeight__lg"
            collapsePanelContentClassname="relatedListOuterDiv p-2 p-md-3"
            isCollapsed
            layout={RAFLayout.OneColumnLayout}
          >
            <textarea
              id={`tta_${question.name}`}
              className="e-control e-textbox e-lib e-input"
              value={question.value}
              name={question.name}
              onChange={(e) => {
                handleContentChange(e.target.value);
              }}
              // onBlur={(e) => onFocusOut(e.target.value)}
              // style={{ width: width }}
              rows={3}
              // onInput={onInputs}
              // onFocus={onFocus}
              placeholder={question.placeholder}
              disabled={disabled}
            />
          </RAFCollapseWithPlusIcon>
        </CustomCardWidget>
      ) : (
        <textarea
          id={`tta_${question.name}`}
          className="e-control e-textbox e-lib e-input"
          value={question.value}
          name={question.name}
          onChange={(e) => {
            handleContentChange(e.target.value);
          }}
          // onBlur={(e) => onFocusOut(e.target.value)}
          // style={{ width: width }}
          rows={3}
          // onInput={onInputs}
          // onFocus={onFocus}
          placeholder={question.placeholder}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default RAFTextAreaComponent;
