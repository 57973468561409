import React, { useEffect } from "react";
import RAFCustomResponsiveDatePicker from "../../../../../RAFComponents/Inputs/RAFCustomResponsiveDatePicker";
import RAFCustomResponsiveTimePicker from "../../../../../RAFComponents/Inputs/RAFCustomResponsiveTimePicker";
import {
  isNotNullAndUndefined,
  isNullOrUndefined
} from "../../../../../RAFComponents/helpers/utils";

const RAFDateTimePickerComponent = ({ question, displayStyle }) => {
  const [questionValue, setQuestionValue] = React.useState(null);

  const isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }

  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }

  const handleDateChange = (date: Date) => {
    question.value = date;
    setQuestionValue(date);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isNotNullAndUndefined(question.value)) {
        const date = new Date(question.value);
        setQuestionValue(date);
      }
    }, 100);
    // if (isNotNullAndUndefined(question.value)) {
    //   setQuestionValue(question.value);
    // }
  }, []);

  const onChangeMobileTimePicker = (e, question) => {
    let value = e;
    const inputValue = question.value;
    if (isNullOrUndefined(value) && isNotNullAndUndefined(inputValue)) {
      const date = new Date(inputValue);
      date.setHours(0, 0, 0, 0);
      value = null; // date;
    }
    question.value = value;
    setQuestionValue(value);
  };


  return (
    <div className="col-12">
      <div className="row g-2">
        {(displayStyle === "Date" || displayStyle === "DateTime") && (
          <div className={`${displayStyle === "Date" ? "col-md-12" : "col-6"}`}>
            <RAFCustomResponsiveDatePicker
              fieldName={question.name}
              inputvalue={questionValue}
              showClearButton={true}
              // showClearButton={!BrowserIsDevice}
              onChangeMobileDatePicker={handleDateChange}
              disabled={disabled}
            />
          </div>
        )}
        {(displayStyle === "Time" || displayStyle === "DateTime") && (
          <div
            className={`${displayStyle === "Time" ? "col-md-12 " : "col-6 "
              }raf_time_picker_col`}
          >
            <RAFCustomResponsiveTimePicker
              fieldName={question.name}
              inputvalue={questionValue}
              showClearButton={true}
              // showClearButton={!BrowserIsDevice}
              interval={5}
              roundOff={false}
              disabled={disabled}
              onChangeTimePicker={(e) => onChangeMobileTimePicker(e, question)}
              maxTimeStateValue={null}
              minTimeStateValue={null}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RAFDateTimePickerComponent;
