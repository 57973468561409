import React, { useEffect, useRef } from "react";
import {
  Audio,
  HtmlEditor,
  Image,
  Inject,
  IToolbarItems,
  Link,
  PasteCleanup,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  ToolbarSettingsModel,
  Video,
} from "@syncfusion/ej2-react-richtexteditor";
import RAFTabGroupBtn from "../../../../../RAFComponents/Navigation/RAFTabGroupBtn";
import { isHTML } from "../../../../../RAFComponents/helpers/AppHelper";

interface RAFRichTextQuestionRendererProps {
  question?: any;
  showHeader?: boolean; //do not remove this, it is used in the parent component
}

const RAFRichTextEditorComponent: React.FC<
  RAFRichTextQuestionRendererProps
> = ({ question, showHeader }) => {
  const groupBtnItems = [
    {
      iconClass: "fas fa-text",
      id: "simple",
      title: "Text",
    },
    {
      iconClass: "fasr fa-align-center",
      id: "advanced",
      title: "Advanced",
    },
  ];
  const [selectedMode, setSelectedMode] = React.useState("simple");
  const [questionValue, setquestionValue] = React.useState(question.value);
  let textarea = useRef(null);
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }

  const items: (string | IToolbarItems)[] = [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Image",
    "Audio",
    "Video",
    "|",
    "ClearFormat",
    "Print",
    "SourceCode",
    "|",
    "Undo",
    "Redo",
    // {
    //     tooltipText: 'Insert Video',
    //     template: "<button type='button' class='e-tbar-btn e-btn' tabindex='-1' id='custom_tbar_" + field.toString() + "' style ='width:100%'><span class='e-icons e-entity e-entity-video'></span></button>"
    // }
  ];

  const RichTextEditorField = () => {
    const [lastValue, setLastValue] = React.useState(question.value);

    const toolbarSettings: ToolbarSettingsModel = displayMode
      ? {
        items: [],
        enable: false,
        enableFloating: true,
      }
      : {
        items: items,
        enableFloating: true,
      };

    const handleContentChange = React.useCallback(
      (event) => {
        if (event.value !== lastValue) {
          question.value = event.value;

          setLastValue(event.value);
          setquestionValue(event.value);
        }
      },
      [question, lastValue]
    );

    function actionBegineHandler(args) {
      if (args.requestType === "EnterAction") {
        args.cancel = true;
      }
    }

    return (
      <RichTextEditorComponent
        //id="defaultRTE"
        //id={`mention_integration${richtexteditorID}`}
        value={questionValue}
        change={handleContentChange}
        className="rafRichTextEditorComponent tribute-demo-input e-control e-textbox e-lib e-input raf_prevent_submit"
        toolbarSettings={toolbarSettings}
        pasteCleanupSettings={{
          prompt: true,
          plainText: false,
          keepFormat: false,
        }}
        inlineMode={{ enable: false }}
        insertImageSettings={{ saveFormat: "Base64" }}
        enterKey="BR"
        shiftEnterKey="BR"
        //actionBegin={actionBegineHandler.bind(this, input)}
        actionBegin={actionBegineHandler.bind(this)}
        style={{ minHeight: "100px" }}
        readonly={disabled}
      >
        <Inject
          services={[
            HtmlEditor,
            Toolbar,
            Image,
            Link,
            QuickToolbar,
            Audio,
            Video,
            PasteCleanup,
          ]}
        />
      </RichTextEditorComponent>
    );
  };

  const MemoizedRichTextEditorField = React.memo(RichTextEditorField);

  const onChangeMode = (mode) => {
    let value = questionValue;
    if (mode === "simple" && isHTML(value)) {
      const strippedValue = value.replace(/<[^>]*>/g, "");
      const plainTextValue = strippedValue.replace(/&nbsp;/g, " ");

      setquestionValue(plainTextValue);
      question.value = plainTextValue;
      setSelectedMode(mode);
    } else {
      setSelectedMode(mode);
    }
  };

  const handleContentChange = React.useCallback(
    (value) => {
      if (value !== questionValue) {
        setquestionValue(value);
        question.value = value;
      }
    },
    [question, questionValue]
  );

  useEffect(() => {
    if (question.value && isHTML(question.value)) {
      setSelectedMode("advanced");
    }
    if (selectedMode === "simple" && textarea) {
      let scrollHeight = textarea.current.scrollHeight;
      textarea.current.style.height = "auto";
      textarea.current.style.height = `${scrollHeight}px`;
    }
  }, [selectedMode, questionValue]);

  const groupBtnContent = () => {
    return (
      <div className="d-flex align-items-center ms-auto justify-content-end">
        <RAFTabGroupBtn
          items={groupBtnItems}
          mode="IconOnly"
          selectedItem={selectedMode}
          onSelect={(item) => onChangeMode(item.id)}
          field={question["title"]}
        />
      </div>
    );
  };

  return (
    <div className="col-12">
      <div className="d-flex form-label-container mb-2">
        <h5
          className={
            showHeader
              ? "sd-title sd-element__title sd-question__title sd-question__title--required sd-question__title--answer"
              : "spg-title spg-question__title spg-question__title--answer"
          }
          id="sq_110_ariaTitle"
        >
          <span className="sv-string-viewer sv-string-viewer--multiline">
            {question["title"]}
          </span>
          {question.required && (
            <>
              <span
                data-key="req-text"
                className="sd-question__required-text ms-1"
              >
                *
              </span>
            </>
          )}
        </h5>
        {groupBtnContent()}
      </div>

      {selectedMode === "simple" ? (
        <div className="sd-comment__content sd-question__content">
          {" "}
          <textarea
            ref={textarea}
            id={`tta_${question.name}`}
            className="sd-input sd-comment"
            value={questionValue || ""}
            name={question.name}
            onChange={(event) => {
              handleContentChange(event.target.value);
              if (textarea) {
                let scrollHeight = textarea.current.scrollHeight;
                textarea.current.style.height = "auto";
                textarea.current.style.height = `${scrollHeight}px`;
              }
            }}
            style={{
              //width: width,
              height: "auto",
            }}
            rows={4}
            placeholder={question["placeholder"]}
            disabled={disabled}
          />
        </div>
      ) : (
        <MemoizedRichTextEditorField />
      )}
    </div>
  );
};

export default RAFRichTextEditorComponent;
