import React, { useEffect, useRef } from "react";
import { QRCodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";

interface RAFQRCodeQuestionRendererProps {
  question?: any;
}

const RAFQRCodeQuestionRenderer: React.FC<RAFQRCodeQuestionRendererProps> = ({
  question,
}) => {
  const [questionValue, setquestionValue] = React.useState(question.value);
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }

  const handleContentChange = React.useCallback(
    (value) => {
      if (value !== questionValue) {
        setquestionValue(value);
        question.value = value;
      }
    },
    [question, questionValue]
  );

  useEffect(() => {
    if (question.value !== questionValue) {
      setquestionValue(question.value);
    }
  }, [question.value]);
  console.log(question.title);
  return (
    <div className="col-12">
      <div className="d-flex align-items-center">
        <div style={{ paddingTop: "2px" }}>
          <CheckBoxComponent
            name={question.name}
            change={(e) => {
              handleContentChange(e.checked);
            }}
            label={question["title"]}
            cssClass="e-checkbox-light"
            checked={questionValue ?? false}
            disabled={disabled}
          />
        </div>
        <div className="ms-2">
          <label>{question.title}</label>
        </div>
      </div>

      {questionValue && displayMode && (
        <QRCodeGeneratorComponent
          id="barcode"
          width={"80px"}
          height={"80px"}
          displayText={{ visibility: false }}
          value={`${window.location.origin}/RAF/View/${questionValue}`}
          margin={{ left: 0, top: 0, right: 0, bottom: 0 }}
        />
      )}
    </div>
  );
};

export default RAFQRCodeQuestionRenderer;
