import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

import MoodBadIcon from "@mui/icons-material/MoodBad";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";

import Rating, { IconContainerProps } from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const defaultEmojiIcons: {
  icon: React.ReactElement;
  label: string;
}[] = [
  {
    icon: <MoodBadIcon color="error" fontSize="large" />,
    label: "Angry",
  },
  {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
    label: "Very Dissatisfied",
  },
  {
    icon: <SentimentDissatisfiedIcon color="error" fontSize="large" />,
    label: "Dissatisfied",
  },
  {
    //SentimentSatisfiedIcon
    icon: <SentimentNeutralIcon color="warning" fontSize="large" />,
    label: "Neutral",
  },
  {
    icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
    label: "Satisfied",
  },
  {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
    label: "Very Satisfied",
  },
];

function IconContainer(
  args: IconContainerProps,
  customIcons: {
    icon: React.ReactElement;
    label: string;
  }[]
) {
  const { value, ...other } = args;
  if (isNotNullAndUndefined(value) && isNotEmptyArray(customIcons)) {
    const icon = customIcons[value - 1];
    if (isNotNullAndUndefined(icon)) {
      return <span {...other}>{icon.icon}</span>;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

const RAFSentimentRatingComponent = ({ question }) => {
  const [hoverActiveValue, setHoverActiveValue] = React.useState(null);
  const [questionValue, setQuestionValue] = React.useState(question.value);
  let isReadOnly = question["readOnly"];

  let displayMode = false;
  if (question.survey.mode === "display") {
    displayMode = true;
  }
  let disabled = false;
  if (isReadOnly === true || displayMode === true) {
    disabled = true;
  }
  const questionJsonObj = question["jsonObj"];
  const choiceOptions = questionJsonObj["rateValues"];

  let customIcons: {
    icon: React.ReactElement;
    label: string;
  }[] = [];

  if (isNotEmptyArray(choiceOptions)) {
    choiceOptions.forEach((item) => {
      let itemIcon = null;
      if (item.text === "Excited") {
        itemIcon = "Very Satisfied";
      }
      if (item.text === "Happy") {
        itemIcon = "Satisfied";
      }
      if (item.text === "Relaxed") {
        itemIcon = "Neutral";
      }
      if (item.text === "Sad") {
        itemIcon = "Dissatisfied";
      }
      if (item.text === "Anxious") {
        itemIcon = "Very Dissatisfied";
      }
      if (item.text === "Angry") {
        itemIcon = "Angry";
      }

      const selectedEmojiIcon = defaultEmojiIcons.find(
        (x) => x.label === itemIcon
      );
      if (isNotNullAndUndefined(selectedEmojiIcon)) {
        customIcons.push({
          icon: selectedEmojiIcon.icon,
          label: item.text,
        });
      }
    });
  } else {
    customIcons = defaultEmojiIcons;
  }

  const getLabelText = (value: number) => {
    if (isNotNullAndUndefined(value)) {
      const selectedIcon = customIcons[value - 1];
      if (isNotNullAndUndefined(selectedIcon)) {
        return selectedIcon.label;
      }
    } else {
      return null;
    }
  };

  const getRatingValueIndex = (text) => {
    let index = null;
    if (isNotNullAndUndefined(text) && isNotEmptyArray(customIcons)) {
      const itemIndex = customIcons.findIndex((item) => item.label === text);
      if (itemIndex !== -1) {
        index = itemIndex + 1;
      } else {
        index = null;
      }
    }
    return index;
  };

  const onChangeInput = (itemIndex, input) => {
    let value = null;
    if (
      isNotEmptyArray(customIcons) &&
      isNotNullAndUndefined(itemIndex) &&
      itemIndex > 0
    ) {
      const selectedItem = customIcons[itemIndex - 1];
      value = isNotNullAndUndefined(selectedItem) ? selectedItem.label : null;
    }
    if (isNotNullAndUndefined(value)) {
      setQuestionValue(value);
      input.value = value;
    }
  };

  const onChangeActive = (event, itemIndex) => {
    if (
      itemIndex > 0 &&
      isNotNullAndUndefined(itemIndex) &&
      isNotEmptyArray(customIcons)
    ) {
      const hoverItem = customIcons[itemIndex - 1];
      const displayLabel = isNotNullAndUndefined(hoverItem)
        ? hoverItem.label
        : null;
      setHoverActiveValue(displayLabel);
    } else {
      setHoverActiveValue(null);
    }
  };

  return (
    <div className="col-12">
      <div className="d-flex align-items-center flex-wrap">
        <StyledRating
          id={`styled_rating_${question.name}`}
          name={question.name}
          value={getRatingValueIndex(questionValue)}
          onChange={(e, newValue) => {
            if (e.isTrusted) {
              onChangeInput(newValue, question);
            }
          }}
          onChangeActive={(event, newHover) => {
            onChangeActive(event, newHover);
          }}
          IconContainerComponent={(args) => IconContainer(args, customIcons)}
          getLabelText={getLabelText}
          highlightSelectedOnly
          {...(isNotEmptyArray(customIcons)
            ? {
                max: customIcons.length,
              }
            : {})}
          disabled={disabled}
        />
        <div>
          {((isNotNullAndUndefined(hoverActiveValue) &&
            IsNotNullOrWhiteSpace(hoverActiveValue)) ||
            (isNotNullAndUndefined(questionValue) &&
              IsNotNullOrWhiteSpace(questionValue))) && (
            <div
              className="e-badge e-badge-lg e-badge-custom e-badge-info"
              style={{ textTransform: "unset", minWidth: "80px" }}
            >
              {IsNotNullOrWhiteSpace(hoverActiveValue)
                ? hoverActiveValue
                : questionValue}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RAFSentimentRatingComponent;
