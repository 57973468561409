import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { Reducer, useEffect, useReducer, useRef } from "react";
import BodyPartPicker from "../../../../../RAFComponents/Inputs/RAFBodyPartPicker/BodyPartPicker";
import bodyParts from "../../../../../RAFComponents/Inputs/RAFBodyPartPicker/constants/body-parts";
import select from "../../../../../RAFComponents/Inputs/RAFBodyPartPicker/images/body/select.png";
import { BodyPart } from "../../../../../RAFComponents/helpers/types";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";

interface IState {
  showPopout: boolean;
  selectedPartsCount: number;
  selectedGender: "Male" | "Female" | "Other";
  normalizedBodyParts: Map<
    number,
    {
      selected: boolean;
      data: BodyPart;
    }
  >;
}

const RAFBodyPartPickerComponent = ({ question }) => {
  let displayMode = false;
  if (
    isNotNullAndUndefined(question) &&
    isNotNullAndUndefined(question.survey) &&
    isNotNullAndUndefined(question.survey.mode) &&
    question.survey.mode === "display"
  ) {
    displayMode = true;
  }

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showPopout: false,
      selectedPartsCount: 0,
      selectedGender: "Male",
      normalizedBodyParts: new Map<
        number,
        { selected: boolean; data: BodyPart }
      >(),
    }
  );

  const selectedParts = useRef("");

  useEffect(() => {
    const bodyPartsMap = new Map<
      number,
      { selected: boolean; data: BodyPart }
    >();
    // Used to load preselected parts if there are any
    //const selectedParts: string[] = parts !== undefined ? parts.split(',') : [];
    const selectedBodyParts: LookUpRow[] = isNotEmptyArray(question.value)
      ? question.value
      : [];

    bodyParts.forEach((bodyPart) => {
      //bodyPartsMap.set(bodyPart.id, { selected: selectedParts.includes(bodyPart.displayName), data: bodyPart });
      bodyPartsMap.set(bodyPart.id, {
        selected:
          selectedBodyParts &&
          selectedBodyParts.some((x) => x.UID === bodyPart.uid),
        data: bodyPart,
      });
    });

    setState({
      normalizedBodyParts: bodyPartsMap,
      selectedPartsCount: isNotEmptyArray(selectedBodyParts)
        ? selectedBodyParts.length
        : 0,
    });
  }, []);

  const handleBodyPartClick = (bodyPartId: number) => {
    const { normalizedBodyParts, selectedPartsCount } = state;

    setState({
      selectedPartsCount: normalizedBodyParts.get(bodyPartId).selected
        ? selectedPartsCount - 1
        : selectedPartsCount + 1,
    });

    let bodyPartData = normalizedBodyParts.get(bodyPartId);
    bodyPartData.selected = !bodyPartData.selected;

    setState({
      normalizedBodyParts: new Map(
        normalizedBodyParts.set(bodyPartId, bodyPartData)
      ),
    });

    // Get a comma seperated string of all the selected parts
    let selectedPartsArr: string[] = [];

    let selectedBodyParts: LookUpRow[] = [];

    normalizedBodyParts.forEach((part) => {
      if (part.selected) {
        selectedPartsArr.push(part.data.displayName);
        //selectedBodyParts && selectedBodyParts.push({ UID: part.data.id, Value: part.data.name });
        selectedBodyParts &&
          selectedBodyParts.push({ UID: part.data.uid, Value: part.data.name });
      }
    });
    selectedParts.current = selectedPartsArr.join(",");

    //update body parts in the form selectedBodyParts
    question.value = selectedBodyParts;
  };

  const getSelectedPartsList = () => {
    const partsList: JSX.Element[] = [];

    state.normalizedBodyParts.forEach((bodyPartData, bodyPartId) => {
      if (bodyPartData.selected) {
        partsList.push(
          <div
            key={bodyPartId}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {bodyPartData.data.name}
            {displayMode === false && (
              <button
                className="body-part-picker__sidebar__toolbar__search__btn-icon input-btn"
                onClick={() => {
                  handleBodyPartClick(bodyPartId);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16px"
                  height="16px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </button>
            )}
          </div>
        );
      }
    });

    return partsList;
  };

  return (
    <div className="skyFormField" id={`rafdiv${question.name}`}>
      <div
                        className="d-flex"
        style={{ fontSize: "0.75rem" }}
      >
        <button
          type="button"
          disabled={displayMode}
          onClick={() => setState({ showPopout: true })}
          className="bodypart__selector"
        >
          {displayMode === false && (
            <span style={{ marginBottom: "0.25rem" }}>Click to select</span>
          )}
          <img src={select} alt="Click to select" />
        </button>

        <div
          style={{
            padding: "0.25rem",
            border: "solid 1px #C8C8C8",
            marginLeft: "0.5rem",
            // height: "100%",
            maxHeight: "220px",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {state.selectedPartsCount === 0
            ? "No Body Part Selected"
            : getSelectedPartsList()}
        </div>
      </div>
      {state.showPopout && (
        <DialogComponent
          header="Select Body Parts"
          showCloseIcon
          visible={state.showPopout}
          isModal={true}
          cssClass="centerDialog-lg body-party-picker-dialog dlg_new_style fixed-header"
          target="body"
          closeOnEscape={false}
          close={() => setState({ showPopout: false })}
          overlayClick={() => setState({ showPopout: false })}
        >
          <BodyPartPicker
            gender={state.selectedGender}
            bodyParts={state.normalizedBodyParts}
            selectedBodyPartsCount={state.selectedPartsCount}
            handleGenderChange={(selectedGender) =>
              setState({ selectedGender: selectedGender })
            }
            handleBodyPartClick={handleBodyPartClick}
          />
        </DialogComponent>
      )}
    </div>
  );
};

export default React.memo(RAFBodyPartPickerComponent);
