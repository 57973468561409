import React, { useEffect, useState } from "react";
import {
  getFileExtension,
  getBlobDocumentFile,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";

const FileThumbnailComponent = ({ fileItem, index }) => {
  const [fileURL, setFileURL] = useState(null);
  const [fileClassName, setFileClassName] = useState(
    "fiv-viv-lg fiv-viv fiv-icon-blank"
  );

  useEffect(() => {
    if (!fileItem.content) {
      getBlobDocumentFile(fileItem.UID, fileItem.Value).then((blob) => {
        if (blob.type.startsWith("image/")) {
          const url = URL.createObjectURL(blob);
          setFileURL(url);

          // Release object URL after the component unmounts
          return () => URL.revokeObjectURL(url);
        } else {
          const fileNameExtension = fileItem.Value;
          const fileExtension = getFileExtension(fileNameExtension);
          if (isNotNullAndUndefined(fileExtension)) {
            setFileClassName(
              "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension
            );
          }
        }
      });
    }
  }, [fileItem]);

  return (
    <div>
      {fileURL ? (
        <img src={fileURL} alt={fileItem.Value} />
      ) : (
        <div className="fiv-viv-outer">
          <span className={fileClassName}></span>
        </div>
      )}
    </div>
  );
};

export default FileThumbnailComponent;
