import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, { useState } from "react";
import CustomCardWidget from "../../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDetailFieldCustom from "../../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFEmptyState from "../../../../../RAFComponents/Navigation/RAFEmptyState";

import {
  Guid,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../../../RAFComponents/models/Common/RAFDataType";
import {
  hideProgress,
  showProgress,
} from "../../../../../RAFComponents/helpers/AppHelper";
import { TaskRow } from "../../../../../RAFModules/ActiveContacts/Task/TaskRow";
import ManageAction from "../../../../../RAFModules/ActiveContacts/Task/ManageAction";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../../../constants/Common/Constants";
import { ConvertTaskRowToTaskDto } from "../../../../../RAFModules/ActiveContacts/Task/TaskMappingRegister";
import {
  completeTask,
  getTaskDisplayDateAndBadge,
} from "../../../../../RAFModules/ActiveContacts/Task/TaskHelper";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import UserProfilePhoto from "../../../../../RAFModules/ActiveContacts/User/UserProfilePhoto";

//import ManageAction from "../../../../../SkytrustModules/Actions/ManageAction";

const RAFTaskComponentRenderer = ({ question, onActionAdded }) => {
  const [showManageAction, setShowManageAction] = useState(false);
  const [selectedActionID, setSelectedActionID] = useState(null);
  const actionListquestionValue = (question.survey as any).getValue(
    "actionList"
  );
  const secondaryRelatedToUID = (question.survey as any).getValue(
    "secondaryRelatedToUID"
  );

  let displayMode = false;
  if ((question.survey as any).mode === "display") {
    displayMode = true;
  }
  const isDesignMode = question.survey.isDesignMode;
  const newGuid = Guid.newGuid();
  // Get the value of the question

  // let itemRefId =
  //   questionValue && questionValue[question.name + "_actionId"]
  //     ? questionValue[question.name + "_actionId"]
  //     : newGuid;

  let parentQuestion = question.data;
  let parentQuestionName = parentQuestion.name;
  let questionValue = (question.survey as any).getValue(parentQuestionName);

  let itemRefId =
    questionValue && questionValue[question.name]
      ? questionValue[question.name]
      : newGuid;

  const actionsList: TaskRow[] = actionListquestionValue ?? [];

  const onActionSaved = (actionList) => {
    onActionAdded(actionList);
    manageActionDialogClose();
  };

  const manageActionContent = () => {
    if (showManageAction) {
      let questionSurvey = question.survey as any;
      let objectUID = questionSurvey["data"]["UID"];
      let objectName = questionSurvey["data"]["ObjectName"];

      return (
        <ManageAction
          taskUID={selectedActionID}
          actionsList={actionsList}
          question={question}
          questionValue={questionValue}
          itemRefId={itemRefId}
          onActionAdded={(actionsList) => onActionSaved(actionsList)}
          complete={(taskRow, taskStatus) =>
            CompleteTaskClicked(taskRow, taskStatus)
          }
          onClose={manageActionDialogClose}
          isCreateTask={isNotNullAndUndefined(selectedActionID) ? false : true}
          isEditTask={isNotNullAndUndefined(selectedActionID) ? true : false}
          isActive
          secondaryRelatedToUID={itemRefId}
          secondaryRelatedToType={"Step"}
          secondaryRelatedTo={question.name}
          relatedTo={objectName}
          relatedToType={"FormLibrary"}
          relatedToUID={objectUID}
          //defaultTaskType={"Preventative"}
        />
      );
    } else {
      return <></>;
    }
  };

  const manageActionDialogClose = () => {
    setShowManageAction(false);
  };

  const manageActionDialogOpen = () => {
    setShowManageAction(true);
  };

  const addActionButton = () => {
    return (
      <div className="col-auto">
        <RAFButtonComponent
          action={RAFButtonConstant.Add}
          onClick={() => manageActionDialogOpen()}
          isPrimary
          className="btn_brand_primary semi_dark"
        ></RAFButtonComponent>
      </div>
    );
  };

  let filteredActionList: TaskRow[] = isNotEmptyArray(actionsList)
    ? actionsList.filter((action) => action.SecondaryRelatedToUID === itemRefId)
    : null;

  const onActionEditClicked = (actionID) => {
    setSelectedActionID(actionID);
    manageActionDialogOpen();
  };

  const CompleteTaskClicked = (
    selectedTaskRow: TaskRow,
    taskStatus: string
  ) => {
    manageActionDialogOpen();
    let taskRow = ConvertTaskRowToTaskDto(selectedTaskRow);
    CompleteTask(taskRow, taskStatus);
  };

  const CompleteTask = (taskRow: TaskRow, taskStatus: string) => {
    let progressDiv = showProgress("body", true);

    taskRow.TaskStatus = taskStatus;

    //taskRow.CompletedDate = new Date();
    // taskRow.CompletedBy = msalInstance.currentUserName;
    // taskRow.CompletedByUID = msalInstance.currentUserId;
    completeTask(taskRow)
      .then((response) => {
        //if (isNotNullAndUndefined(response)) {
        manageActionDialogClose();
        let updatedActionList = actionsList.map((action) => {
          if (action.UID === taskRow.UID) {
            return {
              ...action,
              TaskStatus: taskRow.TaskStatus,
            };
          } else {
            return action;
          }
        });
        onActionAdded(updatedActionList);
        //if (response) {
        // if (this.props.refreshOnCompleteTask)
        //   this.props.refreshOnCompleteTask();
        //}
        //}
        hideProgress(progressDiv);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <CustomCardWidget removeContentPadding>
        <RAFCollapseWithPlusIcon
          toggleArrowIcon="Arrow"
          allowFullRowClick
          title={"Actions"}
          showSeparator={false}
          collapsePanelHeaderClass="collapsePanel__header__fixedHeight__lg"
          collapsePanelContentClassname="relatedListOuterDiv p-2 p-md-3"
          layout={RAFLayout.OneColumnLayout}
          {...(displayMode === false && isDesignMode === false
            ? { customButton: addActionButton() }
            : {})}
          badge={{
            count: filteredActionList && filteredActionList.length,
            show: true,
            className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
          }}
          isCollapsed={
            isNotNullAndUndefined(secondaryRelatedToUID) &&
            secondaryRelatedToUID === itemRefId
              ? false
              : true
          }
        >
          {isNotEmptyArray(filteredActionList) ? (
            <div className="row gx-0 gy-3">
              {filteredActionList.map((action, index) => {
                const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
                  isNotNullAndUndefined(action) ? action.TaskDate : null,
                  isNotNullAndUndefined(action) ? action.TaskType : null
                );
                let taskDate = taskDisplayDateAndBadge.taskDate;
                let dateBadge = taskDisplayDateAndBadge.dateBadge;
                return (
                  <>
                    <div className="col-12" key={`${action.UID}_${index}`}>
                      <div
                        className="pointer"
                        onClick={() => onActionEditClicked(action.UID)}
                      >
                        <div className="row g-3 align-items-center">
                          <div className="col-auto">
                            {action.TaskStatus === RAFActionStatus.Completed ? (
                              <span className="activityDateSpanSection badge badge-completed">
                                Completed
                              </span>
                            ) : (
                              <span
                                className={
                                  "activityDateSpanSection badge " + dateBadge
                                }
                              >
                                {taskDate}
                              </span>
                            )}
                          </div>
                          <div className="col">
                            <div className="subtitle_1">{action.Title}</div>
                            <div className="body_3">{action.Description}</div>
                          </div>
                          <div className="col-auto">
                            <RAFDetailFieldCustom
                              title="TaskType"
                              value={action["TaskType"]}
                              field={"TaskType"}
                              isColorOption
                              mode="outlineView"
                              moduleName={RAFEntityName.Task}
                              showLabel={false}
                              rowClassName="justify-content-between"
                              labelDivClassName="col-auto"
                              valueDivClassName="col-auto"
                              labelClassName="subtitle_3 medium content_neutral_base"
                              valueClassName="raf_badge"
                            ></RAFDetailFieldCustom>
                          </div>
                          <div
                            className="col-auto"
                            id={`employee_${action.AssigneeUID}`}
                          >
                            <div
                              {...(BrowserIsDevice
                                ? {
                                    onClick: (e) => e.stopPropagation(),
                                  }
                                : {})}
                            >
                              <TooltipComponent content={action.Assignee}>
                                <UserProfilePhoto
                                  uid={action.AssigneeUID}
                                  relatedToType={RAFEntityName.User}
                                  canEdit={false}
                                  profileImgCss={"profileImg-container-xsm"}
                                  progressDivID={`#employee_${action.AssigneeUID}`}
                                  title={action.Assignee}
                                  iconSize="24"
                                  fontSize="10"
                                  emptyAvatarType="Initial"
                                />
                              </TooltipComponent>
                            </div>
                          </div>
                          {false && (
                            <CustomCardWidget
                              cardClassName="surface_neutral_base"
                              cardContentClassName="pt-2"
                            >
                              <div className="d-flex align-items-center">
                                <div className="ecllipseFirstLine subtitle_1">
                                  {action.TaskType}
                                </div>
                                {/* {displayMode === false && ( */}
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Edit}
                                  iconBtn
                                  className="btn_brand_primary transparent ms-2 e-custom-actionbutton new_style raf_xsm"
                                  onClick={() =>
                                    onActionEditClicked(action.UID)
                                  }
                                  enableToolTip={false}
                                ></RAFButtonComponent>
                                {/* )} */}
                              </div>
                              <RAFDetailFieldCustom
                                title="Due Date:"
                                value={action.TaskDate}
                                rowClassName="gy-1 gx-2 align-items-center"
                                valueClassName="body_3 ecllipseFirstLine"
                                labelClassName="details-label subtitle_3_light content_neutral_base caption"
                                labelDivClassName="col-auto"
                                valueDivClassName="col"
                                fieldFormat={{
                                  type: RAFDataType.Date,
                                  format: MomentFormats.DATE,
                                }}
                              ></RAFDetailFieldCustom>
                              {isNotNullAndUndefined(action.Description) ? (
                                <CustomCardWidget
                                  cardContentClassName="p-2"
                                  cardClassName="mt-2"
                                >
                                  <span
                                    className="body_2 description-text"
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        action.Description
                                      ),
                                    }}
                                  ></span>
                                </CustomCardWidget>
                              ) : (
                                ""
                              )}
                            </CustomCardWidget>
                          )}
                          {index + 1 < filteredActionList.length && (
                            <div
                              className="col-12"
                              key={`${action.UID}_${index}__separator`}
                            >
                              <div className="custom__card__separator custom__card__separator__light"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            <RAFEmptyState title="No action added!"></RAFEmptyState>
          )}
        </RAFCollapseWithPlusIcon>
      </CustomCardWidget>
      {showManageAction && (
        <DialogComponent
          id={"manageAction_dlg_outerDiv"}
          // header={"Action"}
          // showCloseIcon
          visible={showManageAction}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          content={manageActionContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={manageActionDialogClose.bind(this)}
        ></DialogComponent>
      )}
    </div>
  );
};

export default React.memo(RAFTaskComponentRenderer);
