import { MapperConfiguration, MappingPair } from "@dynamic-mapper/mapper";
import {
  ProcessLibraryRow,
  IProcessLibraryDto,
  RAFRuleAction,
} from "./ProcessLibraryRow";
import { ConvertObjkeysToLowerCase } from "../../helpers/RMutils";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";

export const ConvertProcessLibraryRowToProcessLibraryDto = (
  formLibraryRow: ProcessLibraryRow
): IProcessLibraryDto => {
  const ProcessLibraryRowToIProcessLibraryDto = new MappingPair<
    ProcessLibraryRow,
    IProcessLibraryDto
  >();
  const ProcessLibraryRowToProcessLibraryDtoConfiguration =
    new MapperConfiguration((cfg) => {
      cfg
        .createAutoMap(ProcessLibraryRowToIProcessLibraryDto, {})
        .forSourceMember("FormLibrarySettings", (opt) => opt.ignore())
        .forSourceMember("FormData", (opt) => opt.ignore())
        .forSourceMember("CollectionName", (opt) => opt.ignore())
        .forSourceMember("ResponsiveMode", (opt) => opt.ignore());
    });

  const mapper =
    ProcessLibraryRowToProcessLibraryDtoConfiguration.createMapper();
  return mapper.map(ProcessLibraryRowToIProcessLibraryDto, formLibraryRow);
};

export const ConvertProcessLibraryDtoToProcessLibraryRow = (
  ProcessLibraryDto: IProcessLibraryDto
): ProcessLibraryRow => {
  const IProcessLibraryDtoToProcessLibraryRow = new MappingPair<
    IProcessLibraryDto,
    ProcessLibraryRow
  >();
  const IProcessLibraryDtoToProcessLibraryRowConfiguration =
    new MapperConfiguration((cfg) => {
      cfg.createAutoMap(IProcessLibraryDtoToProcessLibraryRow, {
        FormLibrarySettings: () => null,
        FormData: () => null,
        CollectionName: () => null,
        FormRules: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) &&
            isNotNullAndUndefined(src.FormRules) &&
            src.FormRules.length > 0
              ? ConvertFormRulesArrayIntoRuleModelArray(src.FormRules)
              : null
          ),
        ResponsiveMode: () => null,
      });
    });

  const mapper =
    IProcessLibraryDtoToProcessLibraryRowConfiguration.createMapper();
  return mapper.map(IProcessLibraryDtoToProcessLibraryRow, ProcessLibraryDto);
};

function ConvertFormRulesArrayIntoRuleModelArray(
  formRules?: RAFRuleAction[]
): RAFRuleAction[] {
  let formRulesList: RAFRuleAction[] = [];
  if (formRules != null) {
    formRules.forEach((item) => {
      ConvertObjkeysToLowerCase(item.RRuleModel);
      formRulesList.push(item);
    });
  }
  return formRulesList;
}
